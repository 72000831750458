import React, { useEffect, useState, useCallback, useRef } from 'react';
import ChartComponent from './ChartComponent';
import api from '../api';
import './MarketOverview.css';
import { CircularProgress } from '@mui/material';

const MarketOverview = ({ selectedLocation, locationInfo, listings, isLoadingListings, selectedDistance }) => {
  const [permitData, setPermitData] = useState(null);
  const [listingsData, setListingsData] = useState(null);
  const [selectedListings, setSelectedListings] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [activeCell, setActiveCell] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const listingsPerPage = 10; // Maximum of 10 listings per page
  const [populationData, setPopulationData] = useState(null);
  const [activePendingData, setActivePendingData] = useState(null);
  const [timeframeFilter, setTimeframeFilter] = useState('seven_days');
  const [selectedCellInfo, setSelectedCellInfo] = useState(null);

  const fetchInProgressRef = useRef({
    permits: false,
    listings: false,
    population: false,
    activePending: false
  });

  // Separate loading states for different sections
  const [loadingStates, setLoadingStates] = useState({
    permits: true,
    listings: true,
    population: true,
    activePending: true
  });

  // Replace the single isLoading state with this function
  const updateLoadingState = (key, value) => {
    setLoadingStates(prev => ({ ...prev, [key]: value }));
  };

  const fetchPermitData = useCallback(async () => {
    if (fetchInProgressRef.current.permits || !locationInfo?.fips_code) return;
    fetchInProgressRef.current.permits = true;

    try {
      const response = await api.get('/get-county-permit-table-and-info', {
        params: { 'county-fips': locationInfo.fips_code },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setPermitData(response.data);
    } catch (error) {
      console.error('Error fetching permit data:', error);
      setPermitData(null);
    } finally {
      fetchInProgressRef.current.permits = false;
      updateLoadingState('permits', false);
    }
  }, [locationInfo?.fips_code]);

  const getDistanceKey = useCallback(() => {
    return selectedDistance <= 3 ? 'three_miles' : 'five_miles';
  }, [selectedDistance]);

  const fetchListingsData = useCallback(async () => {
    if (fetchInProgressRef.current.listings || !locationInfo?.fips_code || !listings?.length) return;
    fetchInProgressRef.current.listings = true;

    try {
        const response = await api.post('/api/get-listings-updates-new', {
            listings: listings
        });

        const distanceKey = getDistanceKey();
        const distanceData = response.data[distanceKey];

        // Check if we have the expected data structure
        if (!distanceData?.seven_days || !distanceData?.thirty_days) {
            console.warn('Response missing required data structure:', response.data);
            setListingsData(null);
            return;
        }

        const processedData = {
            seven_days: {
                ...distanceData.seven_days,
                time_frame: '7_days'
            },
            thirty_days: {
                ...distanceData.thirty_days,
                time_frame: '30_days'
            },
            time_stamp: response.data.time_stamp
        };

        // Verify we have the required data
        if (processedData?.seven_days?.detailed_listings && processedData?.thirty_days?.detailed_listings) {
            setListingsData(processedData);
        } else {
            console.warn('Response missing required listings data:', processedData);
            setListingsData(null);
        }

    } catch (error) {
        console.error('Error fetching listings data:', error);
        setListingsData(null);
    } finally {
        fetchInProgressRef.current.listings = false;
        updateLoadingState('listings', false);
    }
  }, [locationInfo?.fips_code, listings, getDistanceKey]);

  const fetchPopulationData = useCallback(async () => {
    if (fetchInProgressRef.current.population || !locationInfo?.fips_code) return;
    fetchInProgressRef.current.population = true;

    try {
      const response = await api.get('/get-population-growth', {
        params: { 'county-fips': locationInfo.fips_code },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setPopulationData(response.data);
    } catch (error) {
      console.error('Error fetching population data:', error);
      setPopulationData(null);
    } finally {
      fetchInProgressRef.current.population = false;
      updateLoadingState('population', false);
    }
  }, [locationInfo?.fips_code]);

  const fetchActivePendingData = useCallback(async () => {
    if (fetchInProgressRef.current.activePending || !locationInfo?.zip_code) return;
    fetchInProgressRef.current.activePending = true;

    try {
      const response = await api.get('/get-active-pending-listings-by-zip', {
        params: { 'zip-code': locationInfo.zip_code },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setActivePendingData(response.data);
    } catch (error) {
      console.error('Error fetching active/pending listings data:', error);
      setActivePendingData(null);
    } finally {
      fetchInProgressRef.current.activePending = false;
      updateLoadingState('activePending', false);
    }
  }, [locationInfo?.zip_code]);

  useEffect(() => {
    if (!locationInfo?.fips_code || !listings?.length) return;

    // Reset loading states when location changes
    setLoadingStates({
      permits: true,
      listings: true,
      population: true,
      activePending: true
    });

    // Fetch each data type independently
    fetchPermitData();
    fetchListingsData();
    fetchPopulationData();
    fetchActivePendingData();
  }, [locationInfo, fetchPermitData, fetchListingsData, fetchPopulationData, fetchActivePendingData, listings]);

  useEffect(() => {
    if (selectedCellInfo && listingsData?.[timeframeFilter]) {
      const newSelectedListings = listingsData[timeframeFilter].detailed_listings[selectedCellInfo.detailedKey] || [];
      setSelectedListings(newSelectedListings);
      setExpandedRow(selectedCellInfo.index);
      setActiveCell({ 
        rowIndex: selectedCellInfo.index, 
        listingType: selectedCellInfo.listingType 
      });
      setCurrentPage(0); // Reset to first page when changing timeframe
    }
  }, [timeframeFilter, listingsData, selectedCellInfo]);

  if (loadingStates.permits || loadingStates.listings || loadingStates.population || loadingStates.activePending) {
    return (
      <div className="overview-container">
        <div className="loading-container">
          <CircularProgress size={60} style={{ color: '#E26313' }} />
        </div>
      </div>
    );
  }

  if (!locationInfo) {
    return (
      <div className="overview-container">
        <div className="loading-container">
          <CircularProgress size={60} style={{ color: '#E26313' }} />
        </div>
      </div>
    );
  }

  const handleSummaryCellClick = (index, distanceCategory, listingType) => {
    const distancePrefix = `${parseInt(distanceCategory)}_miles`;
    let listingKeyPart = '';

    if (listingType === 'New Listings') {
      listingKeyPart = 'new_listings';
    } else if (listingType === 'Active Listings') {
      listingKeyPart = 'active_listings';
    } else if (listingType === 'Removed Listings') {
      listingKeyPart = 'removed_listings';
    }

    const detailedKey = `${distancePrefix}_${listingKeyPart}`;
    console.log('Detailed Key:', detailedKey);

    if (expandedRow === index && activeCell?.rowIndex === index && activeCell?.listingType === listingType) {
      setExpandedRow(null);
      setSelectedListings(null);
      setActiveCell(null);
      setSelectedCellInfo(null);
    } else {
      setSelectedCellInfo({
        index,
        distanceCategory,
        listingType,
        detailedKey
      });
      
      console.log('TimeframeFilter:', timeframeFilter);
      console.log('ListingsData:', listingsData);
      console.log('Detailed Listings:', listingsData?.[timeframeFilter]?.detailed_listings);
      
      const newSelectedListings = listingsData?.[timeframeFilter]?.detailed_listings?.[detailedKey] || [];
      console.log('Selected Listings:', newSelectedListings);
      
      setSelectedListings(newSelectedListings);
      setCurrentPage(0);
      setActiveCell({ rowIndex: index, listingType: listingType });
      setExpandedRow(index);
    }
  };

  // Pagination logic
  const totalListings = selectedListings ? selectedListings.length : 0;
  const totalPages = Math.ceil(totalListings / listingsPerPage);
  const startIndex = currentPage * listingsPerPage;
  const endIndex = startIndex + listingsPerPage;
  const currentListings = selectedListings ? selectedListings.slice(startIndex, endIndex) : [];

  const currentListingsData = listingsData ? listingsData[timeframeFilter] : null;

  console.log('Debug currentListingsData:', {
    hasData: !!currentListingsData,
    summaryStats: currentListingsData?.summary_statistics,
    selectedDistance,
    entries: currentListingsData?.summary_statistics ? Object.entries(currentListingsData.summary_statistics) : []
  });

  return (
    <div className="overview-wrapper">
      <div className="overview-container">
        <h4>
          {locationInfo.city_name}, {locationInfo.state_abbreviation} is located in Zip Code{' '}
          {locationInfo.zip_code.toString().padStart(5, '0')} and is in {locationInfo.county_name} County
        </h4>
        {populationData && populationData.data && populationData.data.length >= 2 && (
          <h5>
            {locationInfo.county_name} County's population grew{' '}
            <strong style={{color: 'black'}}>{populationData.growth_rates['1_year_growth']?.toFixed(2)}%</strong> to{' '}
            <strong style={{color: 'black'}}>{populationData.recent_month_value?.toLocaleString()}</strong> from{' '}
            <strong style={{color: 'black'}}>{populationData.data[1].resident_population?.toLocaleString()}</strong> for the year{' '}
            <strong style={{color: 'black'}}>{populationData.data[0].year}</strong>. 
            It grew <strong style={{color: 'black'}}>{populationData.growth_rates['3_year_growth']?.toFixed(2)}%</strong> over the past 3 years
            {populationData.growth_rates['10_year_growth'] !== null && (
              <> and <strong style={{color: 'black'}}>{populationData.growth_rates['10_year_growth']?.toFixed(2)}%</strong> over the past 10 years</>
            )}.
          </h5>
        )}
      </div>

      <div className="overview-container">
        <div className="timeframe-toggle" style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '10px', fontWeight: 'bold' }}>Recent data based on:</span>
          <button
            onClick={() => setTimeframeFilter('seven_days')}
            className={`timeframe-button ${timeframeFilter === 'seven_days' ? 'active' : ''}`}
            style={{
              padding: '8px 16px',
              marginRight: '10px',
              backgroundColor: timeframeFilter === 'seven_days' ? '#009999' : '#f0f0f0',
              color: timeframeFilter === 'seven_days' ? 'white' : 'black',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Past Week
          </button>
          <button
            onClick={() => setTimeframeFilter('thirty_days')}
            className={`timeframe-button ${timeframeFilter === 'thirty_days' ? 'active' : ''}`}
            style={{
              padding: '8px 16px',
              backgroundColor: timeframeFilter === 'thirty_days' ? '#009999' : '#f0f0f0',
              color: timeframeFilter === 'thirty_days' ? 'white' : 'black',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Past Month
          </button>
        </div>

        <h4>
          Listings updates using selected filters as of {listingsData?.time_stamp} within {selectedDistance} miles - 
          {timeframeFilter === 'seven_days' ? ' Past Week' : ' Past Month'}
        </h4>

        {currentListingsData?.summary_statistics ? (
          <table className="styled-table">
            <thead>
              <tr>
                <th>Distance</th>
                <th>New Listings</th>
                <th>Active Listings</th>
                <th>Removed Listings</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(currentListingsData.summary_statistics).map(([distCategory, values], index) => {
                console.log('Distance Category:', distCategory, 'Values:', values);
                
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{distCategory}</td>
                      <td 
                        className={`clickable-cell ${activeCell?.rowIndex === index && activeCell?.listingType === 'New Listings' ? 'active-cell' : ''}`}
                        onClick={() => handleSummaryCellClick(index, distCategory, 'New Listings')}
                      >
                        {values['New Listings']}
                      </td>
                      <td 
                        className={`clickable-cell ${activeCell?.rowIndex === index && activeCell?.listingType === 'Active Listings' ? 'active-cell' : ''}`}
                        onClick={() => handleSummaryCellClick(index, distCategory, 'Active Listings')}
                      >
                        {values['Active Listings']}
                      </td>
                      <td 
                        className={`clickable-cell ${activeCell?.rowIndex === index && activeCell?.listingType === 'Removed Listings' ? 'active-cell' : ''}`}
                        onClick={() => handleSummaryCellClick(index, distCategory, 'Removed Listings')}
                      >
                        {values['Removed Listings']}
                      </td>
                    </tr>
                    {expandedRow === index && selectedListings && (
                      <tr className="details-row">
                        <td colSpan={4}>
                          <div className="expanded-details" style={{ background: '#f0f0f0', padding: '10px', border: '1px solid #ccc', marginTop: '10px' }}>
                            <h4>Detailed Listings</h4>
                            {currentListings.length > 0 ? (
                              <React.Fragment>
                                <table className="styled-table">
                                  <thead>
                                    <tr>
                                      <th>Address</th>
                                      <th>Bedrooms</th>
                                      <th>Price</th>
                                      <th>Year Built</th>
                                      <th>Square Footage</th>
                                      <th>Days on Market</th>
                                      <th>Status</th>
                                      <th>Zillow</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {currentListings.map((listing, idx) => (
                                      <tr key={idx}>
                                        <td>{listing.formatted_address}</td>
                                        <td>{listing.bedrooms}</td>
                                        <td>{listing.price}</td>
                                        <td>{listing.year_built}</td>
                                        <td>{listing.square_footage}</td>
                                        <td>{listing.days_on_market}</td>
                                        <td>{listing.status}</td>
                                        <td>
                                          <a
                                            href={listing.Zillow}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: 'none' }}
                                          >
                                            <button style={{ padding: '5px 10px', background: '#0077cc', color: 'white', border: 'none', borderRadius: '5px' }}>
                                              View on Zillow
                                            </button>
                                          </a>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>

                                {/* Pagination Controls */}
                                {totalPages > 1 && (
                                  <div style={{display:'flex', justifyContent:'space-between', marginTop:'10px'}}>
                                    <button 
                                      onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))} 
                                      disabled={currentPage === 0}
                                    >
                                      Previous
                                    </button>
                                    <span>Page {currentPage + 1} of {totalPages}</span>
                                    <button 
                                      onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))} 
                                      disabled={currentPage === totalPages - 1}
                                    >
                                      Next
                                    </button>
                                  </div>
                                )}
                              </React.Fragment>
                            ) : (
                              <p>No listings found for this category.</p>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="loading-container">
            <CircularProgress size={40} style={{ color: '#E26313' }} />
          </div>
        )}
      </div>

      <div className="charts-row">
        {activePendingData?.chart_data && (
          <div className="overview-container">
            <h4>Active and Pending Listings for ZIP {locationInfo.zip_code.toString().padStart(5, '0')}</h4>
            <div className="permit-chart">
              <ChartComponent 
                chartData={{ chart_js_json: activePendingData.chart_data }}
              />
            </div>
          </div>
        )}

        <div className="overview-container">
          <h4>Price vs Square Footage ({timeframeFilter === 'seven_days' ? 'Past Week' : 'Past Month'})</h4>
          <div className="permit-chart">
            {listingsData?.[timeframeFilter]?.has_price_vs_sqft_chart ? (
              <ChartComponent 
                chartData={{ chart_js_json: listingsData[timeframeFilter].price_vs_sqft_chart }}
              />
            ) : listingsData?.[timeframeFilter]?.has_detailed_listings ? (
              <div>Processing chart data...</div>
            ) : (
              <div>No data available</div>
            )}
          </div>
        </div>
      </div>

      <div className="charts-row">
        <div className="overview-container">
          {permitData?.latest_data_date ? (
            <h4>Building Permits for {locationInfo.county_name} County, {locationInfo.state_abbreviation} as of {permitData.latest_data_date}:</h4>
          ) : (
            <h4>Building Permits for {locationInfo.county_name} County, {locationInfo.state_abbreviation}</h4>
          )}
          
          <div className="permit-table">
            {permitData?.headers && permitData?.rows ? (
              <table className="styled-table">
                <thead>
                  <tr>
                    {permitData.headers.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {permitData.rows.map((row, index) => (
                    <tr key={index}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="loading-container">
                <CircularProgress size={40} style={{ color: '#E26313' }} />
              </div>
            )}
          </div>
        </div>

        {permitData?.single_family_permits_chart_js_output && (
          <div className="overview-container">
            <h4>Single Family Home Permits for {locationInfo.county_name} County, {locationInfo.state_abbreviation}</h4>
            <div className="permit-chart">
              <ChartComponent 
                chartData={{ 
                  chart_js_json: permitData.single_family_permits_chart_js_output 
                }} 
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketOverview;
