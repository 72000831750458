import React, { useState, useEffect } from 'react';
import './ListingsFilterModal.css';
import api from '../api';

const ListingsFilterModal = ({ show, onClose, onApply, initialFilters, locationId }) => {
  // Initialize state with default values
  const [priceRange, setPriceRange] = useState({
    min: '250000',
    max: '800000'
  });
  const [bedrooms, setBedrooms] = useState({
    min: '2',
    max: '5'
  });
  const [squareFootage, setSquareFootage] = useState({
    min: '1500',
    max: '4000'
  });
  const [homeType, setHomeType] = useState('single-family');
  const [yearBuilt, setYearBuilt] = useState({
    min: '2021',
    max: ''
  });

  // Update local state when initialFilters or locationId changes
  useEffect(() => {
    if (initialFilters) {
      setPriceRange({
        min: initialFilters.priceMin || '250000',
        max: initialFilters.priceMax || '800000'
      });
      setBedrooms({
        min: initialFilters.bedMin || '2',
        max: initialFilters.bedMax || '5'
      });
      setSquareFootage({
        min: initialFilters.sqftMin || '1500',
        max: initialFilters.sqftMax || '4000'
      });
      setHomeType(initialFilters.homeType || 'single-family');
      setYearBuilt({
        min: initialFilters.yearMin || '2021',
        max: initialFilters.yearMax || ''
      });
    }
  }, [initialFilters, locationId]);

  const handleApply = async () => {
    // Create the data for the backend
    const filterData = {
      location_id: locationId,
      priceMin: priceRange.min,
      priceMax: priceRange.max,
      bedMin: bedrooms.min,
      bedMax: bedrooms.max,
      sqftMin: squareFootage.min,
      sqftMax: squareFootage.max,
      homeType: homeType,
      yearMin: yearBuilt.min,
      yearMax: yearBuilt.max,
    };

    // Create frontend filters structure
    const frontendFilters = {
      priceRange: { min: priceRange.min || '', max: priceRange.max || '' },
      bedroomCount: { min: bedrooms.min || '', max: bedrooms.max || '' },
      squareFootage: { min: squareFootage.min || '', max: squareFootage.max || '' },
      homeType: homeType || 'both',
      yearBuilt: { min: yearBuilt.min || '', max: yearBuilt.max || '' }
    };

    try {
      const response = await api.post('/api/save-filters', filterData, {
        withCredentials: true
      });
      
      if (response.status === 200) {
        // Update both backend and frontend state
        onApply(frontendFilters);
        onClose();
      } else {
        console.error('Failed to save filters');
      }
    } catch (error) {
      console.error('Error saving filters:', error);
    }
  };

  if (!show) return null;

  return (
    <div className="listings-filter-modal modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content text-dark">
          <div className="modal-header">
            <h5 className="modal-title">Listings Filters</h5>
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            
            <div className="mb-3">
              <label className="form-label">Price Range</label>
              <div className="d-flex">
                <input 
                  type="number" 
                  className="form-control me-2" 
                  placeholder="Min" 
                  value={priceRange.min} 
                  onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })} 
                />
                <input 
                  type="number" 
                  className="form-control" 
                  placeholder="Max" 
                  value={priceRange.max} 
                  onChange={(e) => setPriceRange({ ...priceRange, max: e.target.value })} 
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Bedroom Count</label>
              <div className="d-flex">
                <input 
                  type="number" 
                  className="form-control me-2" 
                  placeholder="Min" 
                  value={bedrooms.min} 
                  onChange={(e) => setBedrooms({ ...bedrooms, min: e.target.value })} 
                />
                <input 
                  type="number" 
                  className="form-control" 
                  placeholder="Max" 
                  value={bedrooms.max} 
                  onChange={(e) => setBedrooms({ ...bedrooms, max: e.target.value })} 
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Square Footage</label>
              <div className="d-flex">
                <input 
                  type="number" 
                  className="form-control me-2" 
                  placeholder="Min" 
                  value={squareFootage.min} 
                  onChange={(e) => setSquareFootage({ ...squareFootage, min: e.target.value })} 
                />
                <input 
                  type="number" 
                  className="form-control" 
                  placeholder="Max" 
                  value={squareFootage.max} 
                  onChange={(e) => setSquareFootage({ ...squareFootage, max: e.target.value })} 
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Home Type</label>
              <select 
                className="form-select" 
                value={homeType} 
                onChange={(e) => setHomeType(e.target.value)}
              >
                <option value="attached">Attached</option>
                <option value="single-family">Single Family Home</option>
                <option value="both">Both</option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">Year Built</label>
              <div className="d-flex">
                <input 
                  type="number" 
                  className="form-control me-2" 
                  placeholder="Min" 
                  value={yearBuilt.min} 
                  onChange={(e) => setYearBuilt({ ...yearBuilt, min: e.target.value })} 
                />
                <input 
                  type="number" 
                  className="form-control" 
                  placeholder="Max" 
                  value={yearBuilt.max} 
                  onChange={(e) => setYearBuilt({ ...yearBuilt, max: e.target.value })} 
                />
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
            <button type="button" className="btn btn-primary" onClick={handleApply}>Apply</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingsFilterModal;
