// src/App.jsx

import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Login from './components/Login';
import SignUp from './components/SignUp';
import MacroEconMap from './components/MacroEconMap';
import AuthCallback from './components/AuthCallback';
import './App.css';
import api from './api'; 
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import logger from './utils/logger';

const App = () => {
  const [user, setUser] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDistance, setSelectedDistance] = useState(5);
  const [selectedSection, setSelectedSection] = useState('overview');
  const [isLoading, setIsLoading] = useState(true);

  // NEW: global state for listings filters
  const [listingsFilters, setListingsFilters] = useState({
    priceRange: { min: '', max: '' },
    bedroomCount: { min: '', max: '' },
    squareFootage: { min: '', max: '' },
    homeType: 'both',
    yearBuilt: { min: '', max: '' }
  });

  const navigate = useNavigate();
  const location = useLocation();

  const fetchLocations = useCallback(async (clientId = null) => {
    try {
      logger.log(`Fetching locations for clientId: ${clientId}`);
      
      const idToken = await auth.currentUser?.getIdToken();
      if (!idToken) {
        throw new Error('No ID token available');
      }

      const params = clientId ? { client_id: clientId } : {};
      const response = await api.get('/get-locations', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        params
      });

      if (response.status === 200) {
        setLocations(response.data);
        logger.log(`Fetched locations:`, response.data);

        if (response.data.length > 0 && !selectedLocation) {
          const firstLocationId = String(response.data[0].id);
          setSelectedLocation(firstLocationId);
          logger.log('Selected first location:', firstLocationId);
        }
      } else {
        logger.log('Failed to fetch locations with status:', response.status);
      }
    } catch (error) {
      logger.error('Error fetching locations:', error);
    }
  }, [selectedLocation]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      setIsLoading(true);
      if (firebaseUser) {
        logger.log("Firebase user detected:", firebaseUser.email);
        try {
          const idToken = await firebaseUser.getIdToken();
          const response = await api.post('/firebase-login', { idToken }, { withCredentials: true });

          if (response.data.success) {
            setUser({
              id: response.data.user.id,
              email: response.data.user.email,
              name: response.data.user.name,
              isAdmin: response.data.user.is_admin,
            });
            if (location.pathname === '/auth/callback') {
              navigate('/');
            }
          } else {
            setUser(null);
            await auth.signOut();
          }
        } catch (error) {
          logger.error("Error during backend authentication:", error);
          setUser(null);
          await auth.signOut();
        }
      } else {
        logger.log("No Firebase user detected. User is logged out.");
        setUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [navigate, location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      if (!user || isLoading) return;

      if (user.isAdmin) {
        try {
          const response = await api.get('/api/clients', { withCredentials: true });
          if (response.status === 200) {
            setClients(response.data);
            if (response.data.length > 0 && selectedClient === null) {
              const firstClientId = String(response.data[0].id);
              setSelectedClient(firstClientId);
            }
          }
        } catch (error) {
          logger.error('Error fetching clients:', error);
        }
      }
    };
    
    fetchData();
  }, [user, isLoading, selectedClient]);

  useEffect(() => {
    const fetchLocationsData = async () => {
      if (!user || isLoading) return;
      if (user.isAdmin && !selectedClient) return;
      
      const clientId = user.isAdmin ? selectedClient : user.id;
      await fetchLocations(clientId);
    };

    fetchLocationsData();
  }, [user, isLoading, selectedClient, fetchLocations]);

  const handleClientChange = async (clientId) => {
    logger.log(`Changing selected client to: ${clientId}`);
    setSelectedClient(clientId);
    
    // Fetch locations for the new client
    try {
      const idToken = await auth.currentUser?.getIdToken();
      if (!idToken) {
        throw new Error('No ID token available');
      }

      const response = await api.get('/get-locations', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        params: { client_id: clientId }
      });

      if (response.status === 200) {
        setLocations(response.data);
        // Automatically select the first location for the new client
        if (response.data.length > 0) {
          const firstLocationId = String(response.data[0].id);
          setSelectedLocation(firstLocationId);
          logger.log('Selected first location for new client:', firstLocationId);
        } else {
          setSelectedLocation(null);
        }
      }
    } catch (error) {
      logger.error('Error fetching locations for new client:', error);
      setLocations([]);
      setSelectedLocation(null);
    }
  };

  const handleLocationChange = (locationId) => {
    logger.log(`Changing selected location to: ${locationId}`);
    setSelectedLocation(locationId);
  };

  const handleDistanceChange = (distance) => {
    logger.log(`Changing selected distance to: ${distance}`);
    setSelectedDistance(distance);
  };

  const handleSectionChange = (section) => {
    logger.log(`Changing selected section to: ${section}`);
    setSelectedSection(section);
  };

  const handleLogout = async () => {
    logger.log("Logging out. Clearing all user-related states.");
    try {
      await auth.signOut();
      setUser(null);
      setClients([]);
      setSelectedClient(null);
      setLocations([]);
      setSelectedLocation(null);
      setSelectedDistance(10);
      setSelectedSection('overview');
      navigate('/login');
    } catch (error) {
      logger.error("Logout failed:", error);
      alert("An error occurred during logout. Please try again.");
    }
  };

  // NEW: Callback to handle when filters are applied from the modal
  const handleFiltersApply = useCallback((filters) => {
    logger.log("Applying new listings filters:", filters);
    const formattedFilters = {
      priceRange: { min: filters.priceRange.min || '', max: filters.priceRange.max || '' },
      bedroomCount: { min: filters.bedroomCount.min || '', max: filters.bedroomCount.max || '' },
      squareFootage: { min: filters.squareFootage.min || '', max: filters.squareFootage.max || '' },
      homeType: filters.homeType || 'both',
      yearBuilt: { min: filters.yearBuilt.min || '', max: filters.yearBuilt.max || '' }
    };
    setListingsFilters(formattedFilters);
  }, []);

  if (isLoading) {
    return (
      <div className="container mt-5 pt-5">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="app">
      <Navbar 
        user={user} 
        setUser={setUser}
        clients={clients}
        selectedClient={selectedClient}
        onClientChange={handleClientChange}
        locations={locations}
        selectedLocation={selectedLocation}
        onLocationChange={handleLocationChange}
        selectedDistance={selectedDistance}
        onDistanceChange={handleDistanceChange}
        onLogout={handleLogout}
        onFiltersApply={handleFiltersApply}
        listingsFilters={listingsFilters}
      />
      <div className="container-fluid mt-5 pt-5">
        <Routes>
          <Route 
            path="/" 
            element={
              <Home 
                user={user}
                selectedLocation={selectedLocation}
                selectedDistance={selectedDistance}
                selectedClient={selectedClient}
                selectedSection={selectedSection}
                onSectionChange={handleSectionChange}
                listingsFilters={listingsFilters}
                locations={locations}
              />
            } 
          />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/macro-econ-index" element={<MacroEconMap />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
