// src/components/ChartComponent.js
import React, { useEffect, useRef, memo } from 'react';
import Chart from 'chart.js/auto';

const ChartComponent = memo(({ chartData }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  console.log('chartData in ChartComponent:', chartData);

  useEffect(() => {
    if (!chartData) return;
    
    const ctx = chartRef.current.getContext('2d');
    
    // Cleanup previous chart instance if it exists
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Parse the chart_js_json string into a JavaScript object
    const chartConfig = chartData.chart_js_json ? JSON.parse(chartData.chart_js_json) : chartData;

    // Store the new chart instance
    chartInstance.current = new Chart(ctx, chartConfig);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartData]);

  return <canvas ref={chartRef}></canvas>;
}, (prevProps, nextProps) => {
  // Only re-render if the chartData has actually changed
  if (!prevProps.chartData || !nextProps.chartData) return false;
  
  const prevConfig = prevProps.chartData.chart_js_json 
    ? prevProps.chartData.chart_js_json 
    : JSON.stringify(prevProps.chartData);
    
  const nextConfig = nextProps.chartData.chart_js_json 
    ? nextProps.chartData.chart_js_json 
    : JSON.stringify(nextProps.chartData);
    
  return prevConfig === nextConfig;
});

export default ChartComponent;
