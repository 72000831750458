// src/components/MacroEconMap.jsx

import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import ColoredTableCell from './ColoredTableCell'; // Import the component
import api from '../api';
import 'mapbox-gl/dist/mapbox-gl.css'; // Import Mapbox GL CSS
import './MacroEconMap.css'; // Import CSS for styling
import { CircularProgress } from '@mui/material';

// Import the existing ChartComponent
import ChartComponent from './ChartComponent';

  // Import PropTypes for type checking (optional but recommended)
import PropTypes from 'prop-types';

// Set your Mapbox access token using environment variables for security
mapboxgl.accessToken =  'pk.eyJ1IjoibWljaGFlbC1ydCIsImEiOiJjbTFmY2gxZGYydjBhMmptejRkMXY3NnE4In0.HLgD2rXqws3hQ0Wr7X7PQw';


// Define chart configurations outside the component to maintain a stable reference
const chartConfigs = [
  {
    id: 'median_income',
    title: 'Median Household Income Over Time',
    endpoint: '/get-median-income-county',
  },
  {
    id: 'resident_population',
    title: 'Resident Population Over Time',
    endpoint: '/get-resident-population-county',
  },
  {
    id: 'subprime_population',
    title: 'Subprime Population Over Time',
    endpoint: '/get-subprime-population-county',
  },
  {
    id: 'gdp_all_industries',
    title: 'GDP All Industries Over Time',
    endpoint: '/get-gdp-all-industries-county',
  },
  {
    id: 'private_establishments',
    title: 'Private Establishments Over Time',
    endpoint: '/get-private-establishments-county',
  },
  {
    id: 'county_permits',
    title: 'Nearby Permits for Selected County',
    endpoint: '/get_permits_for_single_county_query',
  },
  {
    id: 'nearby_permits',
    title: 'Nearby Permits Based on Selected Distance',
    endpoint: '/get-nearby-permits',
    requiresDistance: true,
  },
  {
    id: 'county-median-listing-price',
    title: 'Median Listing Price for Selected County',
    endpoint: '/get-county-median-listing-price',
  },
  {
    id: 'county-median-days-on-market',
    title: 'Median Days on Market for Selected County',
    endpoint: '/get-county-median-days-on-market',
  },
  {
    id: 'get-county-active-pending-ratio',
    title: 'Active/Pending Listings Ratio for Selected County',
    endpoint: '/get-county-active-pending-ratio',
  },
  {
    id: 'get-county-price-increase-decrease',
    title: 'Price Increases vs Decreases for Selected County',
    endpoint: '/get-county-price-increase-decrease',
  }
];

const MacroEconMap = ({ selectedDistance, selectedLocation, locationInfo }) => {
  console.log("MacroEconMap received props:", { selectedDistance, selectedLocation, locationInfo });
  const mapContainerRef = useRef(null); // Reference to the map container div
  const mapRef = useRef(null); // To store the map instance
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCounty, setSelectedCounty] = useState(null); // To store selected county data
  const [nearestCounties, setNearestCounties] = useState([]); // To store nearest counties data
  const [countyData, setCountyData] = useState({}); // To store local JSON data
  const [columnRanges, setColumnRanges] = useState({}); // To store min and max for each column
  const [showLegend, setShowLegend] = useState(false); // State to control legend visibility
  const [isMapLoaded, setIsMapLoaded] = useState(false); // State to track map load

  // State to manage chart data, loading, and errors for each chart
  const [charts, setCharts] = useState(
    chartConfigs.reduce((acc, chart) => {
      acc[chart.id] = { data: null, loading: false, error: null };
      return acc;
    }, {})
  );

  // Ref for the community marker
  const communityMarkerRef = useRef(null);

  // Initialize the map once when countyData is loaded
  useEffect(() => {
    if (!countyData || Object.keys(countyData).length === 0) {
      console.log("County data not loaded yet.");
      return;
    }

    if (mapRef.current) {
      console.log("Map is already initialized.");
      return;
    }

    try {
      // Initialize Mapbox map
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [-98, 38.88], // Default center
        zoom: 3,
        width: '100%',
        height: '100%',
        preserveDrawingBuffer: true
      });

      // Add zoom controls
      mapRef.current.addControl(new mapboxgl.NavigationControl());

      mapRef.current.on('load', async () => {
        console.log("Map has loaded.");
        setIsMapLoaded(true); // Set map as loaded

        try {
          // Fetch GeoJSON data
          const geoJsonResponse = await fetch('https://raw.githubusercontent.com/plotly/datasets/master/geojson-counties-fips.json');
          const geojson = await geoJsonResponse.json();

          // Merge your data with the GeoJSON features
          geojson.features.forEach(feature => {
            const fips = feature.properties.GEOID || feature.id;

            if (countyData[fips]) {
              feature.properties = {
                ...feature.properties,
                ...countyData[fips]
              };
            } else {
              feature.properties.final_index_value = 'NA';
              feature.properties.final_index_percentile = 'NA';
              feature.properties.region_name = 'Unknown Region';
              // ... set other NA values ...
            }
          });

          // Add the source
          mapRef.current.addSource('counties', {
            type: 'geojson',
            data: geojson,
          });

          // Add the layer with original styling
          mapRef.current.addLayer({
            id: 'counties-layer',
            type: 'fill',
            source: 'counties',
            paint: {
              'fill-color': [
                'case',
                ['==', ['get', 'final_index_percentile'], 'NA'],
                '#cccccc',
                [
                  'interpolate',
                  ['linear'],
                  ['get', 'final_index_percentile'],
                  0, '#ffffcc',
                  20, '#c7e9b4',
                  40, '#7fcdbb',
                  60, '#41b6c4',
                  80, '#1d91c0',
                  100, '#225ea8',
                ],
              ],
              'fill-opacity': 0.75,
              'fill-outline-color': '#ffffff',
            }
          });

          // Create popup for hover
          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
          });

          // Add hover effect
          mapRef.current.on('mousemove', 'counties-layer', (e) => {
            mapRef.current.getCanvas().style.cursor = 'pointer';
            const feature = e.features[0];

            if (typeof feature.properties.final_index_value === 'number') {
              const htmlContent = `
                <strong>${feature.properties.region_name}</strong><br/>
                Final Index Value: ${feature.properties.final_index_value.toFixed(4)}<br/>
                Realtorch Index Percentile: ${feature.properties.final_index_percentile.toFixed(2)}%<br/>
                Population Percentile: ${feature.properties.population_harmonic_mean_percentile.toFixed(2)}%<br/>
                GDP Percentile: ${feature.properties.gdp_harmonic_mean_percentile.toFixed(2)}%<br/>
                Establishments Percentile: ${feature.properties.establishments_harmonic_mean_percentile.toFixed(2)}%<br/>
                Income Percentile: ${feature.properties.income_harmonic_mean_percentile.toFixed(2)}%<br/>
                Employment Percentile: ${feature.properties.employment_harmonic_mean_percentile.toFixed(2)}%
              `;
              popup.setLngLat(e.lngLat).setHTML(htmlContent).addTo(mapRef.current);
            }
          });

          // Remove hover effects
          mapRef.current.on('mouseleave', 'counties-layer', () => {
            mapRef.current.getCanvas().style.cursor = '';
            popup.remove();
          });

          // Add click event listener
          mapRef.current.on('click', 'counties-layer', handleCountyClick);

          setIsLoading(false);
          setShowLegend(true);

        } catch (error) {
          console.error('Error loading data:', error);
          setError(error);
          setIsLoading(false);
        }
      });

    } catch (err) {
      console.error("Error initializing map:", err);
      setError(err);
      setIsLoading(false);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [countyData]);

  // Add community marker and zoom into it whenever locationInfo change
  useEffect(() => {
    const addMarker = () => {
      try {
        // Remove any existing marker
        if (communityMarkerRef.current) {
          communityMarkerRef.current.remove();
        }

        console.log('Adding marker at:', locationInfo);

        // Create the marker and attach a popup
        const popupContent = `
          <div style="padding: 8px;">
            <div style="font-weight: bold; margin-bottom: 4px;">
              Your Community
            </div>
          </div>
        `;
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          offset: [0, 15],
          anchor: 'top',
        }).setHTML(popupContent);

        const marker = new mapboxgl.Marker({
          color: '#E26313',
        })
          .setLngLat([locationInfo.longitude, locationInfo.latitude])
          .setPopup(popup)
          .addTo(mapRef.current);

        // Show popup on hover
        marker.getElement().addEventListener('mouseenter', () => popup.addTo(mapRef.current));
        marker.getElement().addEventListener('mouseleave', () => popup.remove());

        // Center the map on the marker
        mapRef.current.flyTo({
          center: [locationInfo.longitude, locationInfo.latitude],
          zoom: 8,
          essential: true,
          duration: 1000,
        });

        communityMarkerRef.current = marker;
      } catch (error) {
        console.error('Error adding marker:', error);
      }
    };

    if (isMapLoaded && locationInfo) {
      addMarker();
    }

    // Cleanup marker on component unmount or when dependencies change
    return () => {
      if (communityMarkerRef.current) {
        communityMarkerRef.current.remove();
      }
    };
  }, [isMapLoaded, locationInfo]);

  // Update the fetchCountyData function to properly handle the response
  useEffect(() => {
    const fetchCountyData = async () => {
      try {
        const response = await api.get('/get-macro-econ-map');
        
        // Parse the data if it's a string
        let data = response.data;
        if (typeof data === 'string') {
          try {
            data = JSON.parse(data);
          } catch (parseError) {
            console.error('Error parsing JSON response:', parseError);
            throw new Error('Failed to parse server response');
          }
        }
        
        // Debug log the data type and structure
        console.log("Data type:", typeof data);
        console.log("Is Array:", Array.isArray(data));
        console.log("Sample of data:", data?.slice?.(0, 2));

        if (!data || !Array.isArray(data)) {
          throw new Error(`Invalid response format. Expected array but got ${typeof data}`);
        }

        // Convert the array of objects into a lookup object by FIPS code
        const lookup = {};
        data.forEach(county => {
          // Ensure FIPS is padded to 5 digits
          const fips = county.fips.toString().padStart(5, '0');
          lookup[fips] = {
            fips: fips,
            region_name: county.region_name,
            population_harmonic_mean_percentile: county.population_harmonic_mean_percentile,
            gdp_harmonic_mean_percentile: county.gdp_harmonic_mean_percentile,
            establishments_harmonic_mean_percentile: county.establishments_harmonic_mean_percentile,
            income_harmonic_mean_percentile: county.income_harmonic_mean_percentile,
            employment_harmonic_mean_percentile: county.employment_harmonic_mean_percentile,
            final_index_value: county.final_index_value,
            final_index_percentile: county.final_index_percentile,
          };
        });
        
        setCountyData(lookup);
        console.log("Successfully processed data into lookup object");
      } catch (err) {
        console.error('Error fetching county data:', err);
        setError(err.message || 'Failed to fetch county data');
      }
    };

    fetchCountyData();
  }, []); // Empty dependency array ensures this runs once

  // Calculate min and max for each numerical column
  useEffect(() => {
    if (Object.keys(countyData).length === 0) return;

    const columnsToColor = [
      'population_harmonic_mean_percentile',
      'gdp_harmonic_mean_percentile',
      'establishments_harmonic_mean_percentile',
      'income_harmonic_mean_percentile',
      'employment_harmonic_mean_percentile',
      'final_index_value',
      'final_index_percentile'
    ];

    const ranges = {};

    columnsToColor.forEach(column => {
      const values = Object.values(countyData)
        .map(item => item[column])
        .filter(val => typeof val === 'number');

      if (values.length > 0) {
        const min = Math.min(...values);
        const max = Math.max(...values);
        ranges[column] = { min, max };
      } else {
        ranges[column] = { min: 0, max: 0 }; // Default values if no data
      }
    });

    setColumnRanges(ranges);
    console.log("Column ranges for color scaling:", ranges);
  }, [countyData]);

  // Define the click handler function
  const handleCountyClick = async (e) => {
    const feature = e.features[0];
    const fips = feature.properties.GEOID || feature.id;
    const regionName = feature.properties.region_name || 'Unknown Region';

    console.log(`Clicked on county: ${regionName} (FIPS: ${fips})`);

    try {
      const response = await api.get('/api/get-nearest-counties', {
        params: { fips: fips },
        withCredentials: true,
      });

      if (response.data.nearest_counties) {
        setNearestCounties(response.data.nearest_counties);
        setSelectedCounty({
          fips: fips,
          region_name: regionName,
        });
      }
    } catch (error) {
      console.error('Error fetching nearest counties:', error);
      setError(error.response?.data?.error || 'Failed to fetch nearest counties');
    }
  };

  // Fetch chart data whenever selectedDistance or selectedCounty changes
  useEffect(() => {
    if (!selectedCounty) return; // No county selected yet

    const fetchCharts = async () => {
      const { fips } = selectedCounty;

      // Create an array of promises for all chart fetches
      const fetchPromises = chartConfigs.map(async (chart) => {
        // Update loading state
        setCharts((prevCharts) => ({
          ...prevCharts,
          [chart.id]: { ...prevCharts[chart.id], loading: true, error: null, data: null },
        }));

        try {
          const params = { 'county-fips': fips };
          if (chart.requiresDistance) {
            params.distance = selectedDistance;
          }

          const chartResponse = await api.get(chart.endpoint, {
            params: params,
          });

          // Assuming chartResponse.data is a stringified JSON
          // Set chartData to match ChartComponent's expected prop structure
          setCharts((prevCharts) => ({
            ...prevCharts,
            [chart.id]: { ...prevCharts[chart.id], data: { chart_js_json: chartResponse.data }, loading: false },
          }));
        } catch (error) {
          console.error(`Error fetching chart data for ${chart.id}:`, error);
          setCharts((prevCharts) => ({
            ...prevCharts,
            [chart.id]: {
              ...prevCharts[chart.id],
              error: error.response?.data?.error || 'Failed to load chart data.',
              loading: false,
            },
          }));
        }
      });

      // Wait for all chart fetches to complete
      await Promise.all(fetchPromises);
    };

    fetchCharts();
  }, [selectedDistance, selectedCounty]); // Removed chartConfigs from dependencies

  // Add this useEffect after your other useEffects
  useEffect(() => {
    if (!locationInfo?.fips_code || !mapRef.current || !isMapLoaded || !countyData) {
      console.log("Not ready to auto-select county:", {
        hasFipsCode: !!locationInfo?.fips_code,
        hasMap: !!mapRef.current,
        isMapLoaded,
        hasCountyData: !!countyData
      });
      return;
    }

    console.log("Auto-selecting county with FIPS:", locationInfo.fips_code);

    // Create a simulated feature object matching the structure expected by handleCountyClick
    const simulatedFeature = {
      features: [{
        properties: {
          GEOID: locationInfo.fips_code,
          region_name: countyData[locationInfo.fips_code]?.region_name || 'Unknown Region'
        }
      }]
    };

    // Call handleCountyClick with the simulated feature
    handleCountyClick(simulatedFeature);

  }, [locationInfo?.fips_code, isMapLoaded, countyData]); // Dependencies

  return (
    <div className="container">
      <div className="map-container" ref={mapContainerRef}>
        {isLoading && (
          <div className="loading-overlay" style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
          }}>
            <div className="loading-content" style={{
              textAlign: 'center'
            }}>
              <CircularProgress size={40} style={{ color: '#E26313' }} />
              <p style={{ marginTop: '10px', color: '#666' }}>Loading map data...</p>
            </div>
          </div>
        )}
        {error && (
          <div className="overlay error">
            <p>Error loading map: {error}</p>
          </div>
        )}
        {/* Conditionally render the legend */}
        {showLegend && (
          <div className="legend">
            {/* Create the vertical gradient bar */}
            <div className="legend-gradient"></div>
            {/* Add vertical labels */}
            <div className="legend-labels">
              {[0, 20, 40, 60, 80, 100].reverse().map(label => (
                <span key={label}>{label}</span>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="table-container">
        {/* Display selected county and nearest counties data */}
        {selectedCounty && (
          <div className="county-info">
            <h3>Selected County: {selectedCounty.region_name} (FIPS: {selectedCounty.fips})</h3>
            {nearestCounties.length > 0 ? (
              <div>
                <h4>19 Nearest Counties:</h4>
                <table>
                  <thead>
                    <tr>
                      <th>FIPS</th>
                      <th>Region Name</th>
                      <th>Population</th>
                      <th>GDP</th>
                      <th>Establishments</th>
                      <th>Income</th>
                      <th>Employment</th>
                      <th>Realtorch Index Value</th>
                      <th>Realtorch Index Percentile</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nearestCounties.map((county, index) => {
                      const fipsStr = county.fips.toString().padStart(5, '0');
                      const countyInfo = countyData[fipsStr];
                      return (
                        <tr key={index}>
                          <td>{county.fips}</td>
                          <td>{countyInfo ? countyInfo.region_name : 'Data Not Available'}</td>
                          <ColoredTableCell
                            value={countyInfo ? countyInfo.population_harmonic_mean_percentile : 'NA'}
                            column="population_harmonic_mean_percentile"
                            columnRanges={columnRanges}
                          />
                          <ColoredTableCell
                            value={countyInfo ? countyInfo.gdp_harmonic_mean_percentile : 'NA'}
                            column="gdp_harmonic_mean_percentile"
                            columnRanges={columnRanges}
                          />
                          <ColoredTableCell
                            value={countyInfo ? countyInfo.establishments_harmonic_mean_percentile : 'NA'}
                            column="establishments_harmonic_mean_percentile"
                            columnRanges={columnRanges}
                          />
                          <ColoredTableCell
                            value={countyInfo ? countyInfo.income_harmonic_mean_percentile : 'NA'}
                            column="income_harmonic_mean_percentile"
                            columnRanges={columnRanges}
                          />
                          <ColoredTableCell
                            value={countyInfo ? countyInfo.employment_harmonic_mean_percentile : 'NA'}
                            column="employment_harmonic_mean_percentile"
                            columnRanges={columnRanges}
                          />
                          <ColoredTableCell
                            value={countyInfo ? countyInfo.final_index_value : 'NA'}
                            column="final_index_value"
                            columnRanges={columnRanges}
                          />
                          <ColoredTableCell
                            value={countyInfo ? countyInfo.final_index_percentile : 'NA'}
                            column="final_index_percentile"
                            columnRanges={columnRanges}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No nearby counties found.</p>
            )}

            {/* Chart Sections */}
            <div className="chart-sections">
              {chartConfigs.map((chart) => (
                <div key={chart.id} className="chart-container">
                  <h4>{chart.title}</h4>
                  {charts[chart.id].loading && <p>Loading chart...</p>}
                  {charts[chart.id].error && <p className="error">Error loading chart: {charts[chart.id].error}</p>}
                  {charts[chart.id].data && <ChartComponent chartData={charts[chart.id].data} />}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Update PropTypes (keep these)
MacroEconMap.propTypes = {
  selectedDistance: PropTypes.number,
  selectedLocation: PropTypes.string,
  locationInfo: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    fips_code: PropTypes.string,
  }),
};

export default MacroEconMap;