import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MarketOverview from './MarketOverview';
// import MarketEvaluator from './MarketEvaluator';
import ProductEvaluator from './ProductEvaluator';
import ClusteredListings from './ClusteredListings';
import MacroEconMap from './MacroEconMap';
import ZipCodeMap from './ZipCodeMap';
import LocalDemographics from './LocalDemographics';
import './Home.css';
import api from '../api';
import { CircularProgress } from '@mui/material';
import ChatBox from './ChatBox';

const Home = ({ 
  user, 
  selectedLocation, 
  selectedDistance, 
  selectedClient, 
  selectedSection, 
  onSectionChange,
  listingsFilters,
  locations
}) => {
  const [locationInfo, setLocationInfo] = useState(null);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [locationListings, setLocationListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [isLoadingListings, setIsLoadingListings] = useState(false);
  const [filtersInitialized, setFiltersInitialized] = useState(false);

  // location info contains  
  // "latitude"
  // "longitude"
  // "zip_code"
  // "fips_code"
  // "city_name"
  // "state_abbreviation"
  // "county_name"
  // "population"
  // "density"

  // Fetch coordinates when selectedLocation changes
  useEffect(() => {
    let isMounted = true;
    const fetchLocationInfo = async () => {
      if (!selectedLocation) {
        setLocationInfo(null);
        return;
      }
      
      setIsLoadingLocation(true);
      try {
        const response = await api.get('/api/get-location-details', {
          params: { locationId: selectedLocation },
        });
        if (isMounted) {  // Only update state if component is still mounted
          console.log('Received location info:', response.data);
          setLocationInfo(response.data);
        }
      } catch (err) {
        console.error('Error fetching location info:', err);
        if (isMounted) {
          setLocationInfo(null);
        }
      } finally {
        if (isMounted) {
          setIsLoadingLocation(false);
        }
      }
    };

    fetchLocationInfo();
    
    return () => {
      isMounted = false;  // Cleanup function
    };
  }, [selectedLocation]);

  // Add a new useEffect to fetch listings when locationInfo changes
  useEffect(() => {
    const fetchListings = async () => {
      if (!locationInfo || !selectedLocation) return;

      setIsLoadingListings(true);
      try {
        const response = await api.get('/api/get-listings', {
          params: {
            locationId: selectedLocation,
            latitude: locationInfo.latitude,
            longitude: locationInfo.longitude,
            yearsOld: 3 // You can make this configurable if needed
          }
        });

        console.log('Received listings data:', {
          listings: response.data.listings,
          totalCount: response.data.total_count
        });
        
        setLocationListings(response.data.listings);
      } catch (err) {
        console.error('Error fetching listings:', err);
        setLocationListings([]);
      } finally {
        setIsLoadingListings(false);
      }
    };

    fetchListings();
  }, [locationInfo, selectedLocation]);

  // Add new effect to track when filters are initialized
  useEffect(() => {
    if (listingsFilters) {
      setFiltersInitialized(true);
    }
  }, [listingsFilters]);

  // Replace the existing filtering useEffect with this new version
  useEffect(() => {
    console.log('Filtering triggered:', {
      totalListings: locationListings.length,
      filtersInitialized,
      filters: listingsFilters,
    });

    if (!locationListings.length) {
      console.log('No location listings available');
      setFilteredListings([]);
      return;
    }

    // If filters haven't been initialized yet, use all listings
    if (!filtersInitialized) {
      console.log('Filters not yet initialized, using all listings');
      setFilteredListings(locationListings);
      return;
    }

    const filtered = locationListings.filter((listing) => {
      const listingPrice = Number(listing.price);
      const listingSqFt = Number(listing.square_footage);
      const listingBedrooms = Number(listing.bedrooms);
      const listingYearBuilt = listing.year_built ? Number(listing.year_built) : null;

      // Extract filter values with fallback to undefined
      const priceMin = Number(listingsFilters.priceRange.min) || undefined;
      const priceMax = Number(listingsFilters.priceRange.max) || undefined;
      const bedroomsMin = Number(listingsFilters.bedroomCount.min) || undefined;
      const bedroomsMax = Number(listingsFilters.bedroomCount.max) || undefined;
      const sqftMin = Number(listingsFilters.squareFootage.min) || undefined;
      const sqftMax = Number(listingsFilters.squareFootage.max) || undefined;
      const yearMin = Number(listingsFilters.yearBuilt.min) || undefined;
      const yearMax = Number(listingsFilters.yearBuilt.max) || undefined;

      // Check each filter
      const meetsPrice =
        (!priceMin || listingPrice >= priceMin) &&
        (!priceMax || listingPrice <= priceMax);

      const meetsBedrooms =
        (!bedroomsMin || listingBedrooms >= bedroomsMin) &&
        (!bedroomsMax || listingBedrooms <= bedroomsMax);

      const meetsSqft =
        (!sqftMin || listingSqFt >= sqftMin) &&
        (!sqftMax || listingSqFt <= sqftMax);

      const meetsHomeType =
        listingsFilters.homeType === 'both' ||
        (listingsFilters.homeType === 'single-family' &&
          listing.property_type?.toLowerCase() === 'single family') ||
        (listingsFilters.homeType === 'attached' &&
          listing.property_type?.toLowerCase() !== 'single family');

      const meetsYearBuilt =
        (!yearMin || (listingYearBuilt !== null && listingYearBuilt >= yearMin)) &&
        (!yearMax || (listingYearBuilt !== null && listingYearBuilt <= yearMax));

      // Debug logging
      if (locationListings.indexOf(listing) < 2) {
        console.log('Filter check for listing:', {
          address: listing.formatted_address,
          rawValues: {
            price: listing.price,
            bedrooms: listing.bedrooms,
            sqft: listing.square_footage,
            propertyType: listing.property_type,
            yearBuilt: listing.year_built,
          },
          filterValues: {
            price: { min: priceMin, max: priceMax },
            bedrooms: { min: bedroomsMin, max: bedroomsMax },
            sqft: { min: sqftMin, max: sqftMax },
            homeType: listingsFilters.homeType,
            yearBuilt: { min: yearMin, max: yearMax },
          },
          conditions: {
            meetsPrice,
            meetsBedrooms,
            meetsSqft,
            meetsHomeType,
            meetsYearBuilt,
          },
        });
      }

      return (
        meetsPrice &&
        meetsBedrooms &&
        meetsSqft &&
        meetsHomeType &&
        meetsYearBuilt
      );
    });

    console.log('Filtering results:', {
      originalCount: locationListings.length,
      filteredCount: filtered.length,
      filters: listingsFilters,
    });

    setFilteredListings(filtered);
  }, [locationListings, listingsFilters, filtersInitialized]);

  return (
    <div className="home-container">
      {user ? (
        <>
          <div className="content">
            <div className="section-buttons">
              <button 
                onClick={() => onSectionChange('overview')} 
                className={selectedSection === 'overview' ? 'active' : ''}
              >
                Market Overview
              </button>
              <button 
                onClick={() => onSectionChange('demographics')} 
                className={selectedSection === 'demographics' ? 'active' : ''}
              >
                Local Demographics
              </button>
              <button 
                onClick={() => onSectionChange('product')} 
                className={selectedSection === 'product' ? 'active' : ''}
              >
                Product Evaluator
              </button>
              <button 
                onClick={() => onSectionChange('cluster')} 
                className={selectedSection === 'cluster' ? 'active' : ''}
              >
                Listings Evaluator
              </button>
              <button 
                onClick={() => onSectionChange('macro')} 
                className={selectedSection === 'macro' ? 'active' : ''}
              >
                Macro Economic Index
              </button>
              <button 
                onClick={() => onSectionChange('zipcode')} 
                className={selectedSection === 'zipcode' ? 'active' : ''}
              >
                Zip Code Map
              </button>
            </div>

            {/* Show loading message under buttons */}
            {isLoadingLocation && (
              <div className="loading-message">
                <CircularProgress size={60} style={{ color: '#E26313' }} />
              </div>
            )}

            {/* Render the selected section */}
            {!isLoadingLocation && (
              <>
                {selectedSection === 'overview' && (
                  <MarketOverview 
                    selectedLocation={selectedLocation}
                    locationInfo={locationInfo}
                    listings={filteredListings}
                    isLoadingListings={isLoadingListings}
                    selectedDistance={selectedDistance}
                  />
                )}
                {selectedSection === 'demographics' && (
                  <LocalDemographics 
                    selectedLocation={selectedLocation}
                    locationInfo={locationInfo}
                    selectedDistance={selectedDistance}
                  />
                )}
                {/* {selectedSection === 'market' && (
                  <MarketEvaluator 
                    selectedLocation={selectedLocation} 
                    selectedDistance={selectedDistance} 
                    selectedClient={selectedClient} 
                    listings={filteredListings}
                    isLoadingListings={isLoadingListings}
                  />
                )} */}
                {selectedSection === 'product' && (
                  <ProductEvaluator 
                    selectedLocation={selectedLocation} 
                    selectedDistance={selectedDistance} 
                    selectedClient={selectedClient} 
                    listings={filteredListings}
                    isLoadingListings={isLoadingListings}
                  />
                )}
                {selectedSection === 'cluster' && locationInfo && (
                  <ClusteredListings 
                    selectedLocation={selectedLocation} 
                    selectedDistance={selectedDistance} 
                    selectedClient={selectedClient}
                    listings={filteredListings}
                    isLoadingListings={isLoadingListings}
                    locationInfo={locationInfo}
                    listingsFilters={listingsFilters}
                  />
                )}
                {selectedSection === 'macro' && locationInfo && (
                  <MacroEconMap 
                    selectedDistance={selectedDistance} 
                    selectedLocation={selectedLocation}
                    locationInfo={locationInfo} 
                    listings={filteredListings}
                    isLoadingListings={isLoadingListings}
                  />
                )}
                {(selectedSection === 'macro' || selectedSection === 'zipcode') && !locationInfo && !isLoadingLocation && (
                  <div className="loading-message">
                    <p>Please select a location to view this section.</p>
                  </div>
                )}
                {selectedSection === 'zipcode' && locationInfo && (
                  <ZipCodeMap 
                    selectedLocation={selectedLocation}
                    locationInfo={locationInfo} 
                    listings={filteredListings}
                    isLoadingListings={isLoadingListings}
                  />
                )}
              </>
            )}
          </div>
          <ChatBox 
            userId={user.id} 
            locations={locations}
          />
        </>
      ) : (
        <div className="home-container">
          <p>Please log in or sign up to see your application.</p>
          <div className="auth-buttons">
            <Link to="/login">
              <button className="auth-button btn btn-primary">Login</button>
            </Link>
            <Link to="/sign-up">
              <button className="auth-button btn btn-primary">Sign Up</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
