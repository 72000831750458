import React, { useState, useEffect, useRef, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import './ClusteredListings.css';
import api from '../api';
import DataTable from 'react-data-table-component';
import Chart from 'chart.js/auto';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';

// Replace the hardcoded token with environment variable
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

// Move clusterColors outside the component
const clusterColors = [
  'red', 'blue', 'green', 'orange', 'purple',
  'yellow', 'pink', 'brown', 'cyan', 'magenta',
  'lime', 'indigo', 'teal', 'violet', 'gold',
];

const RegularClusterCharts = ({ data }) => {
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartInstance1 = useRef(null);
  const chartInstance2 = useRef(null);

  useEffect(() => {
    // Cleanup previous charts
    if (chartInstance1.current) {
      chartInstance1.current.destroy();
    }
    if (chartInstance2.current) {
      chartInstance2.current.destroy();
    }

    // Create Price vs Square Footage chart
    if (chartRef1.current && data.price_to_sq_ft_scatter) {
      const ctx1 = chartRef1.current.getContext('2d');
      chartInstance1.current = new Chart(ctx1, {
        type: data.price_to_sq_ft_scatter.type,
        data: data.price_to_sq_ft_scatter.data,
        options: {
          ...data.price_to_sq_ft_scatter.options,
          maintainAspectRatio: false,
          plugins: {
            ...data.price_to_sq_ft_scatter.options.plugins,
            tooltip: {
              callbacks: {
                label: function(context) {
                  const point = context.raw;
                  const pricePerSqFt = point.y / point.x;
                  return [
                    `${context.dataset.label}`,
                    `Price: $${point.y.toLocaleString()}`,
                    `Sqft: ${point.x.toLocaleString()}`,
                    `Price/Sqft: $${pricePerSqFt.toFixed(2)}`
                  ];
                }
              }
            }
          }
        }
      });
    }

    // Create Price vs Days on Market chart
    if (chartRef2.current && data.price_to_days_scatter) {
      const ctx2 = chartRef2.current.getContext('2d');
      chartInstance2.current = new Chart(ctx2, {
        type: data.price_to_days_scatter.type,
        data: data.price_to_days_scatter.data,
        options: {
          ...data.price_to_days_scatter.options,
          maintainAspectRatio: false
        }
      });
    }

    // Cleanup function
    return () => {
      if (chartInstance1.current) {
        chartInstance1.current.destroy();
      }
      if (chartInstance2.current) {
        chartInstance2.current.destroy();
      }
    };
  }, [data]);

  return (
    <div style={{ 
      padding: '20px',
      display: 'flex',
      justifyContent: 'space-around',
      backgroundColor: '#f8f9fa',
      gap: '20px'
    }}>
      <div style={{ 
        width: '49.5%', 
        height: '300px',
        backgroundColor: 'white',
        padding: '15px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <canvas ref={chartRef1} style={{ width: '100%', height: '100%' }}></canvas>
      </div>
      <div style={{ 
        width: '49.5%', 
        height: '300px',
        backgroundColor: 'white',
        padding: '15px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <canvas ref={chartRef2} style={{ width: '100%', height: '100%' }}></canvas>
      </div>
    </div>
  );
};

const ClusteredListings = ({ 
  selectedLocation, 
  selectedDistance, 
  selectedClient,
  listings,
  isLoadingListings,
  locationInfo,
  listingsFilters
}) => {
  // Add console log when component receives props
  console.log('ClusteredListings received props:', {
    hasListings: Boolean(listings),
    listingsCount: listings?.length,
    isLoading: isLoadingListings
  });

  // State declarations
  const [selectedCluster, setSelectedCluster] = useState(null);
  const [clusterData, setClusterData] = useState([]);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [transformedListings, setTransformedListings] = useState([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [error, setError] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [isUpdatingMarkers, setIsUpdatingMarkers] = useState(false);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [showInactive, setShowInactive] = useState(true);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [customClusterListings, setCustomClusterListings] = useState(new Set());
  const [customClusterData, setCustomClusterData] = useState(null);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [showOffMarket, setShowOffMarket] = useState(false);
  const [isLoadingOffMarket, setIsLoadingOffMarket] = useState(false);
  const [offMarketMarkers, setOffMarketMarkers] = useState([]);
  const [isCustomSummary, setIsCustomSummary] = useState(false);
  const [showListingsDetails, setShowListingsDetails] = useState(false);

  // Refs
  const popupRef = useRef(null);
  const mapRef = useRef(null);
  const offMarketMarkersRef = useRef([]);
  const communityMarkerRef = useRef(null);
  const drawRef = useRef(null);
  const showOffMarketRef = useRef(showOffMarket);
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const priceDistributionChartRef = useRef(null);
  const monthlySalesChartRef = useRef(null);

  // Add useEffect to initialize the popup
  useEffect(() => {
    // Initialize the popup
    popupRef.current = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    // Cleanup on unmount
    return () => {
      if (popupRef.current) {
        popupRef.current.remove();
      }
    };
  }, []);

  // Step 1: Define base functions first
  const getClusterColor = useCallback((cluster, status) => {
    return clusterColors[cluster - 1] || 'gray';
  }, []);

  // Step 2: Define clearMapMarkers
  const clearMapMarkers = useCallback(() => {
    mapMarkers.forEach(marker => {
      if (typeof marker.remove === 'function') {
        marker.remove();
      }
    });
  }, [mapMarkers]);

  // Step 3: Define transformListingsData
  const transformListingsData = useCallback((listings) => {
    return listings
      .map(listing => {
        if (!listing.latitude || !listing.longitude) return null;
        
        // Debug log
        console.log('Processing listing:', listing);
        
        let schools = listing.schools;
        try {
          if (typeof listing.schools === 'string') {
            schools = JSON.parse(listing.schools);
          }
        } catch (e) {
          console.error('Error parsing schools in transform:', e);
          schools = null;
        }

        return {
          listingId: listing.id || 'N/A',
          latitude: listing.latitude,
          longitude: listing.longitude,
          address: listing.formatted_address || listing.address || 'N/A',
          bedrooms: listing.bedrooms || 'N/A',
          bathrooms: listing.bathrooms || 'N/A',
          cluster: listing.cluster || 'N/A',
          price: listing.price || 'N/A',
          property_type: listing.property_type || 'N/A',
          year_built: listing.year_built || 'N/A',
          lot_size_sq_ft: listing.lot_size_sq_ft || 'N/A',
          days_on_market: listing.days_on_market || 'N/A',
          status: listing.status || 'N/A',
          garageParkingCapacity: listing.garageParkingCapacity,
          sewer: listing.sewer,
          schools: schools,
          square_footage: listing.square_footage,
          latest_price_date: listing.latest_price_date || 'N/A',
          latest_price_event: listing.latest_price_event || 'N/A',
          latest_price: listing.latest_price || 'N/A'
        };
      })
      .filter(Boolean);
  }, []);

  // Step 4: Define createMarker function separately
  const createMarker = useCallback((listing, mapInstance) => {
    if (!listing.longitude || !listing.latitude) return null;

    // Log the transformed listing data to debug
    // console.log('Creating marker with listing:', listing);

    const el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundColor = getClusterColor(listing.cluster, listing.status);
    el.style.width = '20px';
    el.style.height = '20px';
    el.style.borderRadius = '50%';
    el.style.border = '2px solid white';
    // Store both status and cluster information
    el.dataset.status = listing.status;
    el.dataset.cluster = listing.cluster;

    // Format price with commas
    const formattedPrice = typeof listing.price === 'number' 
      ? listing.price.toLocaleString('en-US', { 
          style: 'currency', 
          currency: 'USD',
          maximumFractionDigits: 0
        })
      : 'N/A';

    // Create popup content with error handling
    const popupContent = `
      <div style="padding: 8px;">
        <div style="font-weight: bold; margin-bottom: 4px;">
          Cluster ${listing.cluster || 'N/A'}
        </div>
        <div style="margin-bottom: 4px;">
          <strong>Address:</strong> ${listing.address !== 'N/A' ? listing.address : 'Address not available'}
        </div>
        <div>
          <strong>Price:</strong> ${formattedPrice}
        </div>
      </div>
    `;

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: 'marker-popup'
    }).setHTML(popupContent);

    const marker = new mapboxgl.Marker(el)
      .setLngLat([listing.longitude, listing.latitude])
      .setPopup(popup);

    if (mapInstance.loaded()) {
      marker.addTo(mapInstance);
    }

    el.addEventListener('mouseenter', () => popup.addTo(mapInstance));
    el.addEventListener('mouseleave', () => popup.remove());

    return marker;
  }, [getClusterColor]);

  // Step 5: Define addMarkersToMap with all dependencies
  const addMarkersToMap = useCallback(async (listingsData, mapInstance) => {
    console.log('addMarkersToMap called with:', {
      listingsCount: listingsData?.length,
      hasMapInstance: Boolean(mapInstance),
      isMapLoaded: mapInstance?.loaded(),
      isUpdatingMarkers,
      hasSelectedCoordinates: Boolean(selectedCoordinates),
      coordinates: selectedCoordinates
    });

    if (!mapInstance || !mapInstance.loaded() || isUpdatingMarkers) {
      console.log('Map not ready or markers already updating');
      return;
    }

    try {
      setIsUpdatingMarkers(true);
      
      // Clear existing markers
      clearMapMarkers();
      
      // Process listings in chunks to prevent UI blocking
      const chunkSize = 50;
      const chunks = [];
      for (let i = 0; i < listingsData.length; i += chunkSize) {
        chunks.push(listingsData.slice(i, i + chunkSize));
      }

      let processedMarkers = [];
      let currentChunk = 0;

      const processNextChunk = () => {
        if (currentChunk >= chunks.length) {
          setMapMarkers(processedMarkers);
          console.log('Processing complete:', {
            totalMarkersCreated: processedMarkers.length,
            hasProcessedMarkers: processedMarkers.length > 0,
            communityCoordinates: selectedCoordinates
          });

          if (processedMarkers.length > 0) {
            // Create bounds that include all markers AND the community marker
            const bounds = new mapboxgl.LngLatBounds();
            
            // Add listing markers to bounds
            processedMarkers.forEach(marker => {
              const lngLat = marker.getLngLat();
              bounds.extend(lngLat);
            });
            
            // Add community marker to bounds if it exists
            if (selectedCoordinates) {
              const popupContent = `
                <div style="padding: 8px;">
                  <div style="font-weight: bold; margin-bottom: 4px;">
                    Your Community
                  </div>
                </div>
              `;
              
              const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
                offset: [0, 15],
                anchor: 'top',
              }).setHTML(popupContent);

              // Create and store the marker using default teardrop style
              communityMarkerRef.current = new mapboxgl.Marker({
                color: '#E26313' // Orange color
              })
                .setLngLat([selectedCoordinates.longitude, selectedCoordinates.latitude])
                .setPopup(popup)
                .addTo(mapInstance);

              // Show popup on hover
              communityMarkerRef.current.getElement().addEventListener('mouseenter', () => popup.addTo(mapInstance));
              communityMarkerRef.current.getElement().addEventListener('mouseleave', () => popup.remove());

              console.log('Adding community marker to bounds:', [selectedCoordinates.longitude, selectedCoordinates.latitude]);
              bounds.extend([selectedCoordinates.longitude, selectedCoordinates.latitude]);
            }
            
            console.log('Final bounds:', {
              north: bounds.getNorth(),
              south: bounds.getSouth(),
              east: bounds.getEast(),
              west: bounds.getWest()
            });

            // Add a slight delay before fitting bounds to ensure all markers are rendered
            setTimeout(() => {
              try {
                mapInstance.fitBounds(bounds, {
                  padding: { top: 50, bottom: 50, left: 50, right: 50 },
                  duration: 1000,
                  maxZoom: 15 // Prevent zooming in too close
                });
                console.log('Successfully fit bounds to map');
              } catch (error) {
                console.error('Error fitting bounds:', error);
              }
            }, 100);
          } else {
            console.log('No markers to fit bounds to');
          }
          setIsUpdatingMarkers(false);
          return;
        }

        const chunk = chunks[currentChunk];
        const newMarkers = chunk
          .map(listing => createMarker(listing, mapInstance))
          .filter(Boolean);

        processedMarkers = [...processedMarkers, ...newMarkers];
        currentChunk++;
        requestAnimationFrame(processNextChunk);
      };

      processNextChunk();
    } catch (err) {
      console.error('Error in addMarkersToMap:', err);
      setIsUpdatingMarkers(false);
    }
  }, [clearMapMarkers, createMarker, isUpdatingMarkers, selectedCoordinates]);

  // Fetch location data when selectedLocation changes
  useEffect(() => {
    const fetchLocationData = async () => {
      if (!selectedLocation) return;

      try {
        // Only fetch location coordinates
        const coordinatesResponse = await api.get('/api/get-location-details', {
          params: { locationId: selectedLocation },
        });
        setSelectedCoordinates(coordinatesResponse.data);
      } catch (error) {
        console.error('Error fetching location data:', error);
        setError('Failed to fetch location data.');
      }
    };

    fetchLocationData();
  }, [selectedLocation]);

  // Update initializeMap to include community marker on load
  const initializeMap = useCallback(() => {
    console.log('Initializing Mapbox map...');
    const mapContainer = document.getElementById('map');
    if (!mapContainer) {
      console.error('Map container not found');
      return;
    }

    try {
      // Only clear previous map if it exists
      if (mapRef.current && !mapRef.current.removed) {
        mapRef.current.remove();
      }

      mapContainer.innerHTML = ''; // Clear previous map content

      const mapInstance = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/satellite-streets-v12',
        center: [selectedCoordinates.longitude, selectedCoordinates.latitude],
        zoom: 14,
        antialias: true,
      });

      mapInstance.on('load', () => {
        
        // Add community marker first
        if (!mapInstance.removed && selectedCoordinates) {
          const popupContent = `
            <div style="padding: 8px;">
              <div style="font-weight: bold; margin-bottom: 4px;">
                Your Community
              </div>
            </div>
          `;
          
          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            offset: [0, 15],
            anchor: 'top',
          }).setHTML(popupContent);

          // Create and store the marker using default teardrop style
          communityMarkerRef.current = new mapboxgl.Marker({
            color: '#E26313' // Orange color
          })
            .setLngLat([selectedCoordinates.longitude, selectedCoordinates.latitude])
            .setPopup(popup)
            .addTo(mapInstance);

          // Show popup on hover
          communityMarkerRef.current.getElement().addEventListener('mouseenter', () => popup.addTo(mapInstance));
          communityMarkerRef.current.getElement().addEventListener('mouseleave', () => popup.remove());
        }
        
        // Then add listing markers if they exist
        if (!mapInstance.removed && transformedListings.length > 0) {
          addMarkersToMap(transformedListings, mapInstance);
        }
      });

      mapInstance.on('error', (e) => {
        console.error('Mapbox GL JS Error:', e.error);
      });

      mapRef.current = mapInstance;
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  }, [selectedCoordinates, transformedListings, addMarkersToMap]);

  // Update the selectedCoordinates useEffect
  useEffect(() => {
    console.log('selectedCoordinates useEffect triggered:', {
      hasSelectedCoordinates: Boolean(selectedCoordinates),
      coordinates: selectedCoordinates,
      hasMapRef: Boolean(mapRef.current)
    });

    if (selectedCoordinates && !mapRef.current) {
      console.log('Initializing map because coordinates exist but map does not');
      initializeMap();
    } else if (selectedCoordinates && mapRef.current) {
      // Update community marker position immediately
      if (communityMarkerRef.current) {
        communityMarkerRef.current.remove();
      }

      const popupContent = `
        <div style="padding: 8px;">
          <div style="font-weight: bold; margin-bottom: 4px;">
            Your Community
          </div>
        </div>
      `;
      
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: [0, 15],
        anchor: 'top',
      }).setHTML(popupContent);

      // Create and store the marker using default teardrop style
      communityMarkerRef.current = new mapboxgl.Marker({
        color: '#E26313' // Orange color
      })
        .setLngLat([selectedCoordinates.longitude, selectedCoordinates.latitude])
        .setPopup(popup)
        .addTo(mapRef.current);

      // Show popup on hover
      communityMarkerRef.current.getElement().addEventListener('mouseenter', () => popup.addTo(mapRef.current));
      communityMarkerRef.current.getElement().addEventListener('mouseleave', () => popup.remove());
    }
  }, [selectedCoordinates, initializeMap]);

  // Function to format Zillow URLs
  const formatZillowURL = (address) => {
    const formattedAddress = address
      .replace(/\s+/g, '-')
      .replace(/,/g, '')
      .replace(/--+/g, '-');

    return `https://www.zillow.com/homes/${formattedAddress}`;
  };

  // DataTable columns configuration for Listings Data
  const columns = [
    {
      name: 'Select',
      width: '50px',
      sortable: true,
      selector: row => customClusterListings.has(row.address),
      cell: row => (
        <input
          type="checkbox"
          checked={customClusterListings.has(row.address)}
          onChange={() => handleListingSelect(row.address)}
          style={{ width: '18px', height: '18px' }}
        />
      ),
      ignoreRowClick: true,
    },
    {
      name: 'Cluster',
      selector: row => row.cluster,
      sortable: true,
      width: '100px',
      cell: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: getClusterColor(row.cluster),
              marginRight: '10px'
            }}
          />
          {row.cluster}
        </div>
      )
    },
    {
      name: 'Address',
      selector: row => row.address,
      sortable: true,
      width: '200px',
      cell: row => {
        const parts = row.address.split(',');
        const firstPart = parts[0];
        const remainingParts = parts.slice(1).join(',');
        return (
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            textAlign: 'left'
          }}>
            <div style={{ fontSize: '0.95rem' }}>{firstPart}</div>
            <div style={{ fontSize: '0.95rem' }}>{remainingParts}</div>
          </div>
        );
      }
    },
    {
      name: 'Price',
      selector: row => row.price,
      sortable: true,
      width: '120px',
      cell: row => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{row.price ? `$${formatNumberWithK(row.price)}` : 'N/A'}</div>
            {row.price && row.square_footage ? (
                <div style={{ 
                    fontSize: '0.9em', 
                    color: '#666',
                    marginTop: '4px' 
                }}>
                    ({`$${Math.round(row.price / row.square_footage)}/sq ft`})
                </div>
            ) : null}
        </div>
      )
    },
    {
      name: 'Year Built',
      selector: row => row.year_built,
      sortable: true,
      width: '110px',
    },
    {
      name: 'Features',
      selector: row => row.features,
      sortable: false,
      width: '300px',
      wrap: true,
      cell: row => (
        <div style={{ padding: '8px 0' }}>
          <div style={{ marginBottom: '4px' }}>
            <span style={{ marginRight: '8px' }}>
              <strong>Bedrooms:</strong> {row.bedrooms || 'N/A'}
            </span>
            |
            <span style={{ marginLeft: '8px' }}>
              <strong>Bathrooms:</strong> {row.bathrooms || 'N/A'}
            </span>
          </div>
          <div>
            <span style={{ marginRight: '8px' }}>
              <strong>Garage:</strong> {
                row.garageParkingCapacity || row.garageParkingCapacity === 0
                  ? `${row.garageParkingCapacity} car${row.garageParkingCapacity !== 1 ? 's' : ''}`
                  : 'N/A'
              }
            </span>
            |
            <span style={{ marginLeft: '8px' }}>
              <strong>Sewer:</strong> {row.sewer || 'N/A'}
            </span>
          </div>
        </div>
      )
    },
    {
      name: 'Size',
      selector: row => row.square_footage,
      sortable: false,
      width: '200px',
      wrap: true,
      cell: row => (
        <div style={{ padding: '8px 0' }}>
          <div style={{ marginBottom: '4px' }}>
            <strong>Home:</strong> {row.square_footage ? `${formatNumberInt(row.square_footage)} sq ft` : 'N/A'}
          </div>
          <div>
            <strong>Lot:</strong> {row.lot_size_sq_ft ? `${formatNumberInt(row.lot_size_sq_ft)} sq ft` : 'N/A'}
          </div>
        </div>
      )
    },
    {
      name: 'Latest Event',
      selector: row => row.latest_price_date,
      sortable: true,
      width: '200px',
      wrap: true,
      cell: row => (
        <div style={{ padding: '8px 0' }}>
          <div style={{ marginBottom: '4px' }}>
            <strong>Date:</strong> {row.latest_price_date || 'N/A'}
          </div>
          <div style={{ marginBottom: '4px' }}>
            <strong>Price:</strong> {row.latest_price ? `$${formatNumberWithK(row.latest_price)}` : 'N/A'}
          </div>
          <div>
            <strong>Event:</strong> {row.latest_price_event || 'N/A'}
          </div>
        </div>
      )
    },
    {
      name: 'Days On Mkt',
      selector: row => row.days_on_market,
      sortable: true,
      width: '120px',
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      width: '120px',
      cell: row => (
        <div style={{
          padding: '5px 10px',
          borderRadius: '4px',
          backgroundColor: row.status === 'Active' ? '#28a745' : '#dc3545',
          color: 'white',
          textAlign: 'center'
        }}>
          {row.status}
        </div>
      )
    },
    {
      name: 'Zillow Link',
      cell: row => (
        <a
          href={formatZillowURL(row.address)}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <button style={{ 
            padding: '5px 10px', 
            background: '#fd9245', 
            color: 'white', 
            border: 'none', 
            borderRadius: '5px'
          }}>
            View on Zillow
          </button>
        </a>
      ),
      width: '160px',
    },
    {
      name: 'Schools',
      selector: row => row.schools,
      sortable: false,
      width: '400px',
      wrap: true,
      cell: row => {
        if (!row.schools) return 'N/A';
        try {
          const schools = typeof row.schools === 'string' ? JSON.parse(row.schools) : row.schools;
          if (!Array.isArray(schools) || schools.length === 0) return 'N/A';

          return (
            <div style={{ padding: '8px 0' }}>
              {schools.map((school, index) => (
                <div key={index} style={{ marginBottom: index < schools.length - 1 ? '8px' : '0' }}>
                  <strong>{school.level} School:</strong> {school.name}<br />
                  <span style={{ 
                    color: '#666', 
                    fontSize: '0.9em' 
                  }}>
                    Rating: {school.rating || 'N/A'} | 
                    Distance: {school.distance ? `${school.distance} mi` : 'N/A'}
                  </span>
                </div>
              ))}
            </div>
          );
        } catch (e) {
          console.error('Error processing schools data:', e);
          return 'N/A';
        }
      }
    }
  ];


// Define the cluster columns with formatted numeric values
// Helper function to format numbers with 'k' suffix when needed
const formatNumberWithK = (num) => {
  if (!num && num !== 0) return 'N/A';
  
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }
  if (num >= 1000) {
    return `${Math.round(num / 1000)}k`;
  }
  return num.toLocaleString();
};

// Helper function to format numbers based on their context
const formatNumberInt = (num, isSquareFootage = false) => {
  if (!num && num !== 0) return 'N/A';
  
  if (isSquareFootage) {
    // Always round square footage to whole numbers
    return Math.round(num).toLocaleString();
  }
  
  // For price per square foot, keep 2 decimal places
  if (num < 1000) {
    return num.toFixed(2);
  }
  // For larger numbers, round to whole numbers
  return Math.round(num).toLocaleString();
};

// Define the cluster columns with appropriate formatted numeric values
const clusterColumns = [
  {
    name: 'Cluster',
    width: '90px',
    selector: row => row.cluster,
    sortable: true,
    cell: row => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            backgroundColor: getClusterColor(row.cluster),
            marginRight: '10px'
          }}
        />
        {row.cluster}
      </div>
    )
  },
  {
    name: 'Count',
    width: '90px',
    selector: row => row.count,
    sortable: true,
    cell: row => formatNumberWithK(row.count)
  },
  {
    name: 'Avg\nPrice',
    selector: row => row.avgPrice,
    sortable: true,
    width: '130px',
    cell: row => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{`$${formatNumberWithK(row.avgPrice)}`}</div>
        <div style={{ 
          fontSize: '0.85em', 
          color: '#666',
          marginTop: '4px' 
        }}>
          {`Min: $${formatNumberWithK(row.minPrice)}`}
        </div>
        <div style={{ 
          fontSize: '0.85em', 
          color: '#666'
        }}>
          {`Max: $${formatNumberWithK(row.maxPrice)}`}
        </div>
      </div>
    )
  },
  {
    name: 'Avg\nSq Ft',
    width: '130px',
    selector: row => row.avgSquareFootage,
    sortable: true,
    cell: row => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{formatNumberInt(row.avgSquareFootage, true)}</div>
        <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
          {`Min: ${formatNumberInt(row.minSquareFootage, true)}`}
        </div>
        <div style={{ fontSize: '0.85em', color: '#666' }}>
          {`Max: ${formatNumberInt(row.maxSquareFootage, true)}`}
        </div>
      </div>
    )
  },
  {
    name: 'Avg $\nper Sq Ft',
    width: '130px',
    selector: row => row.avgPricePerSqFt,
    sortable: true,
    cell: row => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{`$${formatNumberInt(row.avgPricePerSqFt)}`}</div>
        <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
          {`Min: $${formatNumberInt(row.minPricePerSqFt)}`}
        </div>
        <div style={{ fontSize: '0.85em', color: '#666' }}>
          {`Max: $${formatNumberInt(row.maxPricePerSqFt)}`}
        </div>
      </div>
    )
  }
];


// Add handler for row expansion
const handleRowExpand = (row) => {
  setExpandedRows(prev => ({
    ...prev,
    [row.cluster]: !prev[row.cluster]
  }));
};

// 1. First define getCustomClusterData
const getCustomClusterData = useCallback(async (selectedListings) => {
  try {
    return {
      onMarketListings: selectedListings.onMarket,
      offMarketListings: selectedListings.offMarket,
    };
  } catch (error) {
    console.log('Error details: ', error);
    return null;
  }
}, []);

// Update handleDrawCreate to properly include off-market listings
const handleDrawCreate = useCallback(async (e) => {
  const drawnPolygon = e.features[0];
  const selectedAddresses = new Set();
  const selectedOffMarketListings = [];
  
  // First, collect addresses of on-market listings within the polygon
  transformedListings.forEach(listing => {
    if (!listing.latitude || !listing.longitude) {
      return;
    }

    const point = turf.point([listing.longitude, listing.latitude]);
    const isInside = turf.booleanPointInPolygon(point, drawnPolygon);

    if (isInside) {
      selectedAddresses.add(listing.address);
    }
  });

  // If off-market toggle is on, collect off-market listings within the polygon
  if (showOffMarketRef.current && offMarketMarkersRef.current.length > 0) {
    offMarketMarkersRef.current.forEach(marker => {
      const lngLat = marker.getLngLat();
      const point = turf.point([lngLat.lng, lngLat.lat]);
      const isInside = turf.booleanPointInPolygon(point, drawnPolygon);

      if (isInside) {
        // Include the full properties object from the marker
        selectedOffMarketListings.push({
          address: marker.properties.address,
          sale_amount: marker.properties.sale_amount,
          sale_date: marker.properties.sale_date,
          latitude: lngLat.lat,
          longitude: lngLat.lng,
          bedrooms: marker.properties.bedrooms,
          bathrooms: marker.properties.bathrooms,
          lot_size_sqft: marker.properties.lot_size_sqft,
          building_sqft: marker.properties.building_sqft,
          property_type: marker.properties.property_type
        });
      }
    });
  }

  // Then, find the original listings that match these addresses
  const selectedListings = listings.filter(listing => 
    selectedAddresses.has(listing.formatted_address)
  );

  if (selectedListings.length > 0 || selectedOffMarketListings.length > 0) {
    // Add detailed logging with all fields
    console.log('Selected on-market listings:', {
      count: selectedListings.length,
      listings: selectedListings.map(listing => ({
        // Log all available fields
        ...listing,
        // Add any computed or transformed fields
        formatted_address: listing.formatted_address || listing.address,
        // Include any nested data structures
        schools: typeof listing.schools === 'string' ? 
          JSON.parse(listing.schools) : listing.schools
      }))
    });

    console.log('Selected off-market listings:', {
      count: selectedOffMarketListings.length,
      listings: selectedOffMarketListings.map(listing => ({
        // Log all available fields
        ...listing,
        // Add any computed fields
        full_address: listing.formatted_address || listing.address || 
          `${listing.street_number || ''} ${listing.street_name || ''}, ${listing.city || ''}, ${listing.state || ''} ${listing.zip || ''}`
      }))
    });

    const requestData = {
      listings: selectedListings,
      selectedLocation: null,
      selectedDistance: null,
      clusters: 1,
      off_market_listings: selectedOffMarketListings
    };

    // Log the final request data
    console.log('Sending request with data:', requestData);

    try {
      const response = await api.post('/api/get-cluster-data-new', requestData);
      
      // Log the complete response data
      console.log('API Response:', {
        status: response.status,
        statusText: response.statusText,
        data: response.data,
        hasClusterSummary: Boolean(response.data?.cluster_summary),
        summaryLength: response.data?.cluster_summary?.length
      });
      
      if (response.data && response.data.cluster_summary) {
        setCustomClusterData(response.data);
        setIsCustomSummary(true);
      } else {
        console.error('Invalid response data:', response.data);
      }
    } catch (error) {
      console.error('Error sending requestData:', error);
    }
  }
}, [transformedListings, listings]);

// 3. Then define handleDrawDelete
const handleDrawDelete = useCallback(() => {
  setCustomClusterListings(new Set());
  setCustomClusterData(null);
  setSortField('');
  setSortDirection('');
}, []);

// Separate the map cleanup into its own useEffect
useEffect(() => {
  // Only handle map cleanup on component unmount
  return () => {
    console.log('Component cleanup triggered');
    
    if (mapRef.current) {
      console.log('Cleaning up map');
      try {
        const map = mapRef.current;
        if (!map.removed) {
          map.remove();
        }
      } catch (error) {
        console.warn('Error removing map:', error);
      }
      mapRef.current = null;
    }

    // Clean up community marker
    if (communityMarkerRef.current) {
      console.log('Cleaning up community marker');
      try {
        communityMarkerRef.current.remove();
      } catch (error) {
        console.warn('Error removing community marker:', error);
      }
      communityMarkerRef.current = null;
    }
  };
}, []); // Empty dependency array since this should only run on unmount

// Keep the other cleanup logic in a separate useEffect
useEffect(() => {
  return () => {
    // Clean up draw controls if they exist
    if (drawRef.current && mapRef.current) {
      try {
        const map = mapRef.current;
        if (map && !map.removed) {
          if (map.listens && map.listens('draw.create')) {
            map.off('draw.create', handleDrawCreate);
          }
          if (map.listens && map.listens('draw.delete')) {
            map.off('draw.delete', handleDrawDelete);
          }
          map.removeControl(drawRef.current);
        }
      } catch (error) {
        console.warn('Error cleaning up draw control:', error);
      }
      drawRef.current = null;
    }

    // Clean up markers
    clearMapMarkers();

    // Remove popup if it exists
    if (popupRef.current) {
      try {
        popupRef.current.remove();
      } catch (error) {
        console.warn('Error removing popup:', error);
      }
      popupRef.current = null;
    }
  };
}, [handleDrawCreate, handleDrawDelete, clearMapMarkers]);

  // Handle cluster count change
  const handleClusterChange = (count) => {
    setSelectedCluster(count);
    // Only enable search if we have listings and they're not loading
    setIsSearchEnabled(!isLoadingListings && listings?.length > 0);
  };

  // Add a useEffect to update search enabled state when listings load
  useEffect(() => {
    if (selectedCluster) {
      setIsSearchEnabled(!isLoadingListings && listings?.length > 0);
    }
  }, [isLoadingListings, listings, selectedCluster]);

  // New function to handle search button click
  const handleSearchClick = () => {
    if (!isSearchEnabled) return;
    setIsSearchEnabled(false);
    
    // Reset to show inactive listings
    setShowInactive(true);
    
    fetchChartsData();
  };

  // Function to fetch cluster and listing data
  const fetchChartsData = async () => {
    console.log('Starting fetchChartsData with:', {
      listingsAvailable: Boolean(listings),
      listingsCount: listings?.length,
      selectedCluster
    });

    setError(null);
    clearMapMarkers();

    try {
      if (!listings || listings.length === 0) {
        console.log('No listings available:', listings);
        throw new Error('No listings data available');
      }

      // Log the first few listings to check their structure
      console.log('Sample listings data:', listings.slice(0, 2));

      const filteredListings = listings.filter(listing => 
        listing.distance && listing.distance <= selectedDistance
      );

      const response = await api.post('/api/get-cluster-data-new', {
        listings: filteredListings,
        selectedLocation,
        selectedDistance,
        clusters: selectedCluster
      });

      console.log('Cluster data response:', response.data);

      if (response.data.listings_table && response.data.listings_table.length > 0) {
        const transformedListingsData = transformListingsData(response.data.listings_table);
        setTransformedListings(transformedListingsData);
        
        // Only add markers if the map is ready
        if (mapRef.current && mapRef.current.loaded()) {
          await addMarkersToMap(transformedListingsData, mapRef.current);
        } else {
          console.warn('Map not ready for markers');
        }
      } else {
        setTransformedListings([]);
        clearMapMarkers();
      }

      if (response.data.cluster_summary && response.data.cluster_summary.length > 0) {
        const transformedClusterData = transformClusterData(response.data.cluster_summary);
        console.log('Raw cluster data:', response.data.cluster_summary);
        console.log('Transformed cluster data:', transformedClusterData);
        setClusterData(transformedClusterData);
      } else {
        console.log('No cluster summary data received');
        setClusterData([]);
      }

    } catch (error) {
      console.error('Error in fetchChartsData:', error);
      setError('Failed to fetch cluster data: ' + error.message);
      setTransformedListings([]);
      setClusterData([]);
      clearMapMarkers();
    } 
  };

  // Add console log to the useEffect
  useEffect(() => {
    console.log('ClusteredListings useEffect triggered:', {
      isLoadingListings,
      hasListings: Boolean(listings),
      listingsCount: listings?.length
    });

    // No initial fetch - just log the status
    if (!isLoadingListings && listings?.length > 0) {
      console.log('Listings loaded and ready for clustering');
    }
  }, [listings, isLoadingListings]);

  // Function to transform cluster data (defined only once)
  const transformClusterData = (clusters) => {
    return clusters.map(cluster => {
      return {
        cluster: cluster.Cluster,
        count: cluster.Count,
        avgPrice: cluster['Avg Price'],
        minPrice: cluster['Min Price'],
        maxPrice: cluster['Max Price'],
        avgSquareFootage: cluster['Avg Square Footage'],
        minSquareFootage: cluster['Min Square Footage'],
        maxSquareFootage: cluster['Max Square Footage'],
        avgPricePerSqFt: cluster['Avg Price per Sq Ft'],
        minPricePerSqFt: cluster['Min Price per Sq Ft'],
        maxPricePerSqFt: cluster['Max Price per Sq Ft'],
        price_to_sq_ft_scatter: cluster.price_to_sq_ft_scatter,
        price_to_days_scatter: cluster.price_to_days_scatter,
        isExpanded: false
      };
    });
  };

  // Step 4: Update the show/hide inactive toggle
  const handleInactiveToggle = useCallback(() => {
    if (isUpdatingMarkers) return;
    
    setShowInactive(!showInactive);
    const markers = document.querySelectorAll('.marker');
    markers.forEach(marker => {
      if (marker.dataset.status === 'Inactive') {
        marker.style.backgroundColor = showInactive ? '#808080' : clusterColors[marker.dataset.cluster - 1];
      }
    });
  }, [isUpdatingMarkers, showInactive]);

  // 4. handleListingSelect
  const handleListingSelect = useCallback(async (address) => {
    setCustomClusterListings(prev => {
      const newSet = new Set(prev);
      if (newSet.has(address)) {
        newSet.delete(address);
      } else {
        newSet.add(address);
      }
      
      setTimeout(async () => {
        console.log('Getting custom cluster data for addresses:', Array.from(newSet));
        const clusterData = await getCustomClusterData(newSet);
        if (clusterData) {
          setCustomClusterData(clusterData);
        }
      }, 0);

      return newSet;
    });
  }, [getCustomClusterData]);

  // 5. toggleSelectMode with handleDrawDelete in dependencies
  const toggleSelectMode = useCallback(() => {
    const newSelectMode = !isSelectMode;
    setIsSelectMode(newSelectMode);

    if (newSelectMode) {
      if (!drawRef.current && mapRef.current) {
        try {
          const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
              polygon: true,
              trash: true
            },
            defaultMode: 'draw_polygon'
          });

          mapRef.current.addControl(draw);
          drawRef.current = draw;

          // Only add listeners if map exists
          if (mapRef.current) {
            mapRef.current.on('draw.create', handleDrawCreate);
            mapRef.current.on('draw.delete', handleDrawDelete);
          }
        } catch (error) {
          console.warn('Error initializing draw mode:', error);
          setIsSelectMode(false);
        }
      }
    } else {
      if (drawRef.current && mapRef.current) {
        try {
          // Only remove listeners if they exist
          if (mapRef.current.listens('draw.create')) {
            mapRef.current.off('draw.create', handleDrawCreate);
          }
          if (mapRef.current.listens('draw.delete')) {
            mapRef.current.off('draw.delete', handleDrawDelete);
          }
          mapRef.current.removeControl(drawRef.current);
        } catch (error) {
          console.warn('Error cleaning up draw mode:', error);
        }
        drawRef.current = null;
      }
      setCustomClusterListings(new Set());
    }
  }, [isSelectMode, handleDrawCreate, handleDrawDelete]);

  // Add this to your cleanup effect
  useEffect(() => {
    return () => {
      if (drawRef.current && mapRef.current) {
        mapRef.current.off('draw.create', handleDrawCreate);
        mapRef.current.off('draw.delete', handleDrawDelete);
        mapRef.current.removeControl(drawRef.current);
      }
    };
  }, [handleDrawCreate, handleDrawDelete]);

  // Add a useEffect to monitor selectedListings changes
  useEffect(() => {
    console.log('customClusterListings updated:', Array.from(customClusterListings));
  }, [customClusterListings]);

  // Add a useEffect to monitor mapMarkers state
  useEffect(() => {
    console.log('mapMarkers state updated, count:', mapMarkers.length);
  }, [mapMarkers]);

  // Add the handleSort function
  const handleSort = useCallback((column, sortDirection) => {
    setSortField(column.name);
    setSortDirection(sortDirection);
  }, []);

  // Update the useEffect for chart creation
  useEffect(() => {
    // Only proceed if we have data and the custom summary is active
    if (!customClusterData?.cluster_summary?.[0] || !isCustomSummary) {
      return;
    }

    const clusterData = customClusterData.cluster_summary[0];
    
    // Capture current ref values
    const localChartRef1 = chartRef1.current;
    const localChartRef2 = chartRef2.current;
    const localPriceDistRef = priceDistributionChartRef.current;
    const localMonthlySalesRef = monthlySalesChartRef.current;
    
    try {
      // Cleanup any existing charts using local refs
      if (localChartRef1?.chart) {
        localChartRef1.chart.destroy();
      }
      if (localChartRef2?.chart) {
        localChartRef2.chart.destroy();
      }
      if (localPriceDistRef?.chart) {
        localPriceDistRef.chart.destroy();
      }
      if (localMonthlySalesRef?.chart) {
        localMonthlySalesRef.chart.destroy();
      }

      // Short delay to ensure DOM is ready after toggle
      setTimeout(() => {
        if (clusterData.price_distribution_chart) {
          // Off-market charts logic
          if (priceDistributionChartRef.current) {
            const priceDistChart = new Chart(
              priceDistributionChartRef.current,
              {
                type: clusterData.price_distribution_chart.type,
                data: clusterData.price_distribution_chart.data,
                options: clusterData.price_distribution_chart.options
              }
            );
            priceDistributionChartRef.current.chart = priceDistChart;
          }

          if (monthlySalesChartRef.current) {
            const monthlySalesChart = new Chart(
              monthlySalesChartRef.current,
              {
                type: clusterData.monthly_sales_chart.type,
                data: clusterData.monthly_sales_chart.data,
                options: clusterData.monthly_sales_chart.options
              }
            );
            monthlySalesChartRef.current.chart = monthlySalesChart;
          }
        } else {
          // On-market charts logic
          if (chartRef1.current && clusterData.price_to_sq_ft_scatter) {
            const ctx1 = chartRef1.current.getContext('2d');
            const chart1 = new Chart(ctx1, {
              type: clusterData.price_to_sq_ft_scatter.type,
              data: clusterData.price_to_sq_ft_scatter.data,
              options: {
                ...clusterData.price_to_sq_ft_scatter.options,
                maintainAspectRatio: false,
                plugins: {
                  ...clusterData.price_to_sq_ft_scatter.options.plugins,
                  tooltip: {
                    callbacks: {
                      label: function(context) {
                        const point = context.raw;
                        const pricePerSqFt = point.y / point.x;
                        return [
                          `${context.dataset.label}`,
                          `Price: $${point.y.toLocaleString()}`,
                          `Sqft: ${point.x.toLocaleString()}`,
                          `Price/Sqft: $${pricePerSqFt.toFixed(2)}`
                        ];
                      }
                    }
                  }
                }
              }
            });
            chartRef1.current.chart = chart1;
          }

          if (chartRef2.current && clusterData.price_to_days_scatter) {
            const ctx2 = chartRef2.current.getContext('2d');
            const chart2 = new Chart(ctx2, {
              type: clusterData.price_to_days_scatter.type,
              data: clusterData.price_to_days_scatter.data,
              options: {
                ...clusterData.price_to_days_scatter.options,
                maintainAspectRatio: false
              }
            });
            chartRef2.current.chart = chart2;
          }
        }
      }, 0);

      // Use local refs in cleanup function
      return () => {
        if (localChartRef1?.chart) {
          localChartRef1.chart.destroy();
        }
        if (localChartRef2?.chart) {
          localChartRef2.chart.destroy();
        }
        if (localPriceDistRef?.chart) {
          localPriceDistRef.chart.destroy();
        }
        if (localMonthlySalesRef?.chart) {
          localMonthlySalesRef.chart.destroy();
        }
      };
    } catch (error) {
      console.error('Error creating charts:', error);
    }
  }, [customClusterData, isCustomSummary]);

  // Add this function after the other API-related functions
  const fetchOffMarketListings = useCallback(async () => {
    if (!selectedCoordinates) return;
    
    setIsLoadingOffMarket(true);
    try {
      const response = await api.get('/api/get-attom-listings', {
        params: {
          locationId: selectedLocation,
          latitude: selectedCoordinates.latitude,
          longitude: selectedCoordinates.longitude
        }
      });

      // Clear existing off-market markers
      setOffMarketMarkers(prevMarkers => {
        prevMarkers.forEach(marker => marker.remove());
        return [];
      });
      
      // Filter off-market listings using only price range
      const filteredListings = response.data.listings.filter(listing => {
        // Extract price filter values with fallback to undefined
        const priceMin = Number(listingsFilters?.priceRange?.min) || undefined;
        const priceMax = Number(listingsFilters?.priceRange?.max) || undefined;
        const listingPrice = Number(listing.sale_amount);

        // Check only price condition
        const meetsPrice =
          (!priceMin || listingPrice >= priceMin) &&
          (!priceMax || listingPrice <= priceMax);

        return meetsPrice;
      });

      // Create markers for filtered off-market listings
      const newMarkers = filteredListings.map(listing => {
        const el = document.createElement('div');
        el.className = 'marker off-market-marker';
        el.style.backgroundColor = '#D3D3D3';
        el.style.width = '20px';
        el.style.height = '20px';
        el.style.borderRadius = '50%';
        el.style.border = '2px solid white';

        // Create the marker and attach the full listing data
        const marker = new mapboxgl.Marker(el)
          .setLngLat([listing.longitude, listing.latitude]);
        
        // Store the full listing data and ensure address is included
        marker.properties = { 
          ...listing,
          address: listing.formatted_address || listing.address || `${listing.street_number || ''} ${listing.street_name || ''}, ${listing.city || ''}, ${listing.state || ''} ${listing.zip || ''}`
        }; 

        // Create a popup for this marker
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          className: 'marker-popup'
        });
        
        // Format date function
        const formatDate = (dateString) => {
          if (!dateString) return '';
          const date = new Date(dateString);
          return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          });
        };

        // Add hover events
        el.addEventListener('mouseenter', () => {
          // Debug logs
          console.log('Marker LngLat:', marker.getLngLat());
          console.log('Marker Properties:', marker.properties);

          popup
            .setLngLat(marker.getLngLat())
            .setHTML(`
              <div style="padding: 8px;">
                <div style="font-weight: bold; color: black; margin-bottom: 5px;">Off Market Listing</div>
                <div style="margin-bottom: 4px; text-transform: capitalize;">
                  <strong>Address:</strong> ${marker.properties.address.toLowerCase().replace(/\b[a-z]{2}\b(?=,?\s+\d{5})/g, match => match.toUpperCase())}
                </div>
                ${marker.properties.sale_amount ? `
                  <div style="margin-bottom: 4px;">
                    <strong>Last Sale:</strong> $${marker.properties.sale_amount.toLocaleString()}
                  </div>` : ''}
                ${marker.properties.sale_date ? `
                  <div style="margin-bottom: 4px;">
                    <strong>Sale Date:</strong> ${formatDate(marker.properties.sale_date)}
                  </div>` : ''}
                ${marker.properties.bedrooms ? `
                  <div style="margin-bottom: 4px;">
                    <strong>Beds:</strong> ${marker.properties.bedrooms}
                  </div>` : ''}
                ${marker.properties.bathrooms ? `
                  <div style="margin-bottom: 4px;">
                    <strong>Baths:</strong> ${marker.properties.bathrooms}
                  </div>` : ''}
                ${marker.properties.building_sqft ? `
                  <div style="margin-bottom: 4px;">
                    <strong>Sqft:</strong> ${marker.properties.building_sqft.toLocaleString()}
                  </div>` : ''}
              </div>
            `)
            .addTo(mapRef.current);
        });

        el.addEventListener('mouseleave', () => {
          popup.remove();
        });
        
        // Add the marker to the map
        if (mapRef.current) {
          marker.addTo(mapRef.current);
        }
        
        return marker;
      });

      setOffMarketMarkers(newMarkers);
      offMarketMarkersRef.current = newMarkers;
    } catch (error) {
      console.error('Error fetching off-market listings:', error);
    } finally {
      setIsLoadingOffMarket(false);
    }
  }, [selectedLocation, selectedCoordinates, mapRef, listingsFilters]);

  // Add toggle function for off-market listings
  const toggleOffMarket = useCallback(() => {
    const newShowOffMarket = !showOffMarket;
    setShowOffMarket(newShowOffMarket);
    
    if (newShowOffMarket) {
      fetchOffMarketListings();
    } else {
      offMarketMarkersRef.current.forEach(marker => marker.remove());
      setOffMarketMarkers([]);
      offMarketMarkersRef.current = [];
    }
  }, [showOffMarket, fetchOffMarketListings]);

  // Update the useEffect that watches showOffMarket
  useEffect(() => {
    showOffMarketRef.current = showOffMarket;
  }, [showOffMarket]);

  // Update the useEffect that watches offMarketMarkers
  useEffect(() => {
    offMarketMarkersRef.current = offMarketMarkers;
  }, [offMarketMarkers]);

  // Add columns definition for the listings details table
  const listingsDetailsColumns = [
    {
      name: 'Address',
      selector: row => row.formatted_address || row.address || 'N/A',
      sortable: true,
      wrap: true,
      width: '250px',
      cell: row => {
        const address = row.formatted_address || row.address;
        if (!address) return 'N/A';
        
        return (
          <div>
            {address.toLowerCase()
              .replace(/\b[a-z]{2}\b(?=,?\s+\d{5})/g, match => match.toUpperCase())
              .replace(/\b\w/g, l => l.toUpperCase())}
          </div>
        );
      }
    },
    {
      name: 'Price',
      selector: row => row.price,
      sortable: true,
      width: '80px',
      cell: row => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{row.price ? `$${formatNumberWithK(row.price)}` : 'N/A'}</div>
          {row.price && row.bldg_sq_ft ? (
            <div style={{ 
              fontSize: '0.9em', 
              color: '#666',
              marginTop: '4px' 
            }}>
              ({`$${Math.round(row.price / row.bldg_sq_ft)}/sq ft`})
            </div>
          ) : null}
        </div>
      )
    },
    {
      name: 'Beds/Baths',
      selector: row => `${row.beds}/${row.bath}`,
      sortable: true,
      width: '110px'
    },
    {
      name: 'Building Sqft',
      selector: row => row.bldg_sq_ft,
      sortable: true,
      width: '120px',
      cell: row => formatNumberInt(row.bldg_sq_ft)
    },
    {
      name: 'Lot Sqft',
      selector: row => row.lot_size_sq_ft,
      sortable: true,
      width: '90px',
      cell: row => formatNumberInt(row.lot_size_sq_ft)
    },
    {
      name: 'Year Built',
      selector: row => row.year_built,
      sortable: true,
      width: '120px'
    },
    {
      name: 'Property Type',
      selector: row => row.property_type,
      sortable: true,
      width: '150px'
    }
  ];

  return (
    <div className="clustered-listings-container">
      {/* Cluster Selection */}
      <h3>Select Clusters</h3>
      <div className="clustered-listings-buttons">
        {[5, 10, 15].map((clusterCount) => (
          <button
            key={clusterCount}
            onClick={() => handleClusterChange(clusterCount)}
            className={`cluster-button ${selectedCluster === clusterCount ? 'active' : ''}`}
            style={{
              padding: '10px 20px',
              backgroundColor: selectedCluster === clusterCount ? '#009999' : 'transparent',
              color: selectedCluster === clusterCount ? 'white' : '#009999',
              border: '2px solid #009999',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            {clusterCount} Clusters
          </button>
        ))}
        
        {/* Search button - disabled by default until cluster is selected */}
        <button
          onClick={handleSearchClick}
          className="cluster-button search-button"
          disabled={!isSearchEnabled || isUpdatingMarkers}
          style={{
            padding: '10px 20px',
            backgroundColor: isSearchEnabled ? '#fd9245' : '#e0e0e0',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: isSearchEnabled && !isUpdatingMarkers ? 'pointer' : 'not-allowed',
            marginLeft: '20px',
            opacity: (!isSearchEnabled || isUpdatingMarkers) ? 0.7 : 1
          }}
        >
          {isLoadingListings ? 'Loading...' : 'Search'}
        </button>

        {/* Inactive toggle button */}
        <button
          onClick={handleInactiveToggle}
          className="cluster-button inactive-toggle-button"
          disabled={isUpdatingMarkers}
          style={{
            padding: '10px 20px',
            backgroundColor: 'transparent',
            color: '#009999',
            border: '2px solid #009999',
            borderRadius: '5px',
            cursor: isUpdatingMarkers ? 'not-allowed' : 'pointer',
            marginLeft: '20px',
            opacity: isUpdatingMarkers ? 0.7 : 1
          }}
        >
          {showInactive ? 'Hide Inactive' : 'Show Inactive'}
        </button>

        <button
          onClick={toggleSelectMode}
          className="cluster-button select-mode-button"
          style={{
            padding: '10px 20px',
            backgroundColor: isSelectMode ? '#009999' : 'transparent',
            color: isSelectMode ? 'white' : '#009999',
            border: '2px solid #009999',
            borderRadius: '5px',
            cursor: 'pointer',
            marginLeft: '20px'
          }}
        >
          {isSelectMode ? 'Cancel Selection' : 'Custom Cluster'}
        </button>

        {/* Add the new Off-Market button */}
        <button
          onClick={toggleOffMarket}
          className="cluster-button off-market-button"
          disabled={!selectedCoordinates}
          style={{
            padding: '10px 20px',
            backgroundColor: showOffMarket ? '#E26313' : 'transparent',
            color: showOffMarket ? 'white' : '#E26313',
            border: '2px solid #E26313',
            borderRadius: '5px',
            cursor: selectedCoordinates ? 'pointer' : 'not-allowed',
            marginLeft: '20px',
            opacity: selectedCoordinates ? 1 : 0.7
          }}
        >
          {isLoadingOffMarket ? 'Loading...' : (showOffMarket ? 'Hide Off-Market' : 'Show Off-Market')}
        </button>
      </div>

      {/* Optional: Display Error Message */}
      {error && (
        <div className="error-message" style={{ color: 'red', marginBottom: '20px' }}>
          {error}
        </div>
      )}

      {/* Map and Cluster Summary Table Container */}
      <div className="map-and-summary-container">
        {/* Map Section */}
        <div className="map-section">
          {/* Map container */}
          <div id="map" className="map"></div>
          
          {/* Legend moved outside map div but still inside map-section */}
          {/* <div className="jobs-legend">
            <div className="jobs-legend-gradient"></div>
            <div className="jobs-legend-labels">
              {[1.0, 0.8, 0.6, 0.4, 0.2, 0.0].map(label => (
                <span key={label}>{label.toFixed(1)}</span>
              ))}
            </div>
          </div> */}
        </div>

        {/* Cluster Summary Table Section */}
        <div className="cluster-summary-section">
          <div style={{ position: 'relative' }}>
            {/* Custom Summary Toggle */}
            <div style={{
              position: 'absolute',
              top: '8px',
              right: '16px',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}>
              <span style={{ 
                fontSize: '0.9rem', 
                color: '#666',
                userSelect: 'none'
              }}>
                Custom
              </span>
              <label className="switch" style={{
                position: 'relative',
                display: 'inline-block',
                width: '40px',
                height: '20px'
              }}>
                <input
                  type="checkbox"
                  checked={isCustomSummary}
                  onChange={() => setIsCustomSummary(prev => !prev)}
                  disabled={!customClusterData}
                  style={{ opacity: 0, width: 0, height: 0 }}
                />
                <span className="slider round" style={{
                  position: 'absolute',
                  cursor: customClusterData ? 'pointer' : 'not-allowed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: isCustomSummary ? '#fd9245' : '#ccc',
                  transition: '0.4s',
                  borderRadius: '34px',
                  opacity: customClusterData ? 1 : 0.5
                }}/>
              </label>
            </div>

            {isCustomSummary && customClusterData && customClusterData.cluster_summary ? (
              <div>
                {/* Toggle button - only show for off-market charts */}
                {customClusterData.cluster_summary[0].price_distribution_chart && (
                  <div 
                    onClick={() => setShowListingsDetails(!showListingsDetails)}
                    style={{ 
                      cursor: 'pointer',
                      padding: '10px',
                      backgroundColor: '#f8f9fa',
                      borderRadius: '4px',
                      marginBottom: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #dee2e6'
                    }}
                  >
                    <span style={{ color: 'black' }}>
                      Click to {showListingsDetails ? 'show summary' : 'show listings details'}
                    </span>
                  </div>
                )}

                {/* Conditional render based on showListingsDetails - only for off-market */}
                {customClusterData.cluster_summary[0].price_distribution_chart ? (
                  !showListingsDetails ? (
                    <DataTable
                      columns={[
                        {
                          name: 'Count',
                          width: '90px',
                          selector: () => customClusterData.cluster_summary[0].Count,
                          cell: () => formatNumberWithK(customClusterData.cluster_summary[0].Count)
                        },
                        {
                          name: 'Avg\nPrice',
                          width: '130px',
                          selector: () => customClusterData.cluster_summary[0]['Avg Price'],
                          cell: () => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <div>{`$${formatNumberWithK(customClusterData.cluster_summary[0]['Avg Price'])}`}</div>
                              <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
                                {`Min: $${formatNumberWithK(customClusterData.cluster_summary[0]['Min Price'])}`}
                              </div>
                              <div style={{ fontSize: '0.85em', color: '#666' }}>
                                {`Max: $${formatNumberWithK(customClusterData.cluster_summary[0]['Max Price'])}`}
                              </div>
                            </div>
                          )
                        },
                        ...(customClusterData.cluster_summary[0]['Avg Square Footage'] ? [{
                          name: 'Avg\nSq Ft',
                          width: '130px',
                          selector: () => customClusterData.cluster_summary[0]['Avg Square Footage'],
                          cell: () => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <div>{formatNumberInt(customClusterData.cluster_summary[0]['Avg Square Footage'], true)}</div>
                              <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
                                {`Min: ${formatNumberInt(customClusterData.cluster_summary[0]['Min Square Footage'], true)}`}
                              </div>
                              <div style={{ fontSize: '0.85em', color: '#666' }}>
                                {`Max: ${formatNumberInt(customClusterData.cluster_summary[0]['Max Square Footage'], true)}`}
                              </div>
                            </div>
                          )
                        }] : []),
                        ...(customClusterData.cluster_summary[0]['Avg Price per Sq Ft'] ? [{
                          name: 'Avg $\nper Sq Ft',
                          width: '130px',
                          selector: () => customClusterData.cluster_summary[0]['Avg Price per Sq Ft'],
                          cell: () => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <div>{`$${formatNumberInt(customClusterData.cluster_summary[0]['Avg Price per Sq Ft'])}`}</div>
                              <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
                                {`Min: $${formatNumberInt(customClusterData.cluster_summary[0]['Min Price per Sq Ft'])}`}
                              </div>
                              <div style={{ fontSize: '0.85em', color: '#666' }}>
                                {`Max: $${formatNumberInt(customClusterData.cluster_summary[0]['Max Price per Sq Ft'])}`}
                              </div>
                            </div>
                          )
                        }] : [])
                      ]}
                      data={[{ ...customClusterData.cluster_summary[0], id: 1 }]}
                      customStyles={{
                        table: {
                          style: {
                            fontSize: '0.9rem',
                          },
                        },
                        rows: {
                          style: {
                            fontSize: '0.9rem',
                            borderBottom: '1px solid #e0e0e0',
                            minHeight: '60px',
                          },
                        },
                        headCells: {
                          style: {
                            fontSize: '0.9rem',
                            borderBottom: '2px solid #e0e0e0',
                            fontWeight: 'bold',
                            padding: '8px 8px',
                            whiteSpace: 'pre-line',
                            wordWrap: 'break-word',
                            height: 'auto',
                            minHeight: '60px',
                          },
                        },
                        cells: {
                          style: {
                            fontSize: '0.9rem',
                            padding: '8px 8px',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                          },
                        }
                      }}
                    />
                  ) : (
                    <DataTable
                      title="Listings Details"
                      columns={listingsDetailsColumns}
                      data={customClusterData.listings_details_json}
                      pagination
                      paginationPerPage={5}
                      paginationRowsPerPageOptions={[5, 10, 15, 20]}
                      customStyles={{
                        table: {
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                          },
                        },
                        rows: {
                          style: {
                            fontSize: '0.9rem',
                            padding: '8px',
                            borderBottom: '1px solid #e0e0e0',
                          },
                        },
                        headCells: {
                          style: {
                            fontSize: '0.9rem',
                            fontWeight: 'bold',
                            padding: '12px 8px',
                            backgroundColor: '#f8f9fa',
                          },
                        },
                        cells: {
                          style: {
                            padding: '8px',
                          },
                        },
                      }}
                    />
                  )
                ) : (
                  // Always show summary table for on-market listings
                  <DataTable
                    columns={[
                      {
                        name: 'Count',
                        width: '90px',
                        selector: () => customClusterData.cluster_summary[0].Count,
                        cell: () => formatNumberWithK(customClusterData.cluster_summary[0].Count)
                      },
                      {
                        name: 'Avg\nPrice',
                        width: '130px',
                        selector: () => customClusterData.cluster_summary[0]['Avg Price'],
                        cell: () => (
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>{`$${formatNumberWithK(customClusterData.cluster_summary[0]['Avg Price'])}`}</div>
                            <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
                              {`Min: $${formatNumberWithK(customClusterData.cluster_summary[0]['Min Price'])}`}
                            </div>
                            <div style={{ fontSize: '0.85em', color: '#666' }}>
                              {`Max: $${formatNumberWithK(customClusterData.cluster_summary[0]['Max Price'])}`}
                            </div>
                          </div>
                        )
                      },
                      ...(customClusterData.cluster_summary[0]['Avg Square Footage'] ? [{
                        name: 'Avg\nSq Ft',
                        width: '130px',
                        selector: () => customClusterData.cluster_summary[0]['Avg Square Footage'],
                        cell: () => (
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>{formatNumberInt(customClusterData.cluster_summary[0]['Avg Square Footage'], true)}</div>
                            <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
                              {`Min: ${formatNumberInt(customClusterData.cluster_summary[0]['Min Square Footage'], true)}`}
                            </div>
                            <div style={{ fontSize: '0.85em', color: '#666' }}>
                              {`Max: ${formatNumberInt(customClusterData.cluster_summary[0]['Max Square Footage'], true)}`}
                            </div>
                          </div>
                        )
                      }] : []),
                      ...(customClusterData.cluster_summary[0]['Avg Price per Sq Ft'] ? [{
                        name: 'Avg $\nper Sq Ft',
                        width: '130px',
                        selector: () => customClusterData.cluster_summary[0]['Avg Price per Sq Ft'],
                        cell: () => (
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>{`$${formatNumberInt(customClusterData.cluster_summary[0]['Avg Price per Sq Ft'])}`}</div>
                            <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
                              {`Min: $${formatNumberInt(customClusterData.cluster_summary[0]['Min Price per Sq Ft'])}`}
                            </div>
                            <div style={{ fontSize: '0.85em', color: '#666' }}>
                              {`Max: $${formatNumberInt(customClusterData.cluster_summary[0]['Max Price per Sq Ft'])}`}
                            </div>
                          </div>
                        )
                      }] : [])
                    ]}
                    data={[{ ...customClusterData.cluster_summary[0], id: 1 }]}
                    customStyles={{
                      table: {
                        style: {
                          fontSize: '0.9rem',
                        },
                      },
                      rows: {
                        style: {
                          fontSize: '0.9rem',
                          borderBottom: '1px solid #e0e0e0',
                          minHeight: '60px',
                        },
                      },
                      headCells: {
                        style: {
                          fontSize: '0.9rem',
                          borderBottom: '2px solid #e0e0e0',
                          fontWeight: 'bold',
                          padding: '8px 8px',
                          whiteSpace: 'pre-line',
                          wordWrap: 'break-word',
                          height: 'auto',
                          minHeight: '60px',
                        },
                      },
                      cells: {
                        style: {
                          fontSize: '0.9rem',
                          padding: '8px 8px',
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                        },
                      }
                    }}
                  />
                )}

                {/* Charts Section */}
                {customClusterData.cluster_summary[0].price_distribution_chart ? (
                  // Off-market charts
                  <div style={{ 
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#f8f9fa',
                    gap: '10px',
                    marginTop: '10px'
                  }}>
                    <div style={{ 
                      width: '49.5%', 
                      height: '300px',
                      backgroundColor: 'white',
                      padding: '4px',
                      borderRadius: '4px',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                    }}>
                      <canvas ref={priceDistributionChartRef}></canvas>
                    </div>
                    <div style={{ 
                      width: '49.5%', 
                      height: '300px',
                      backgroundColor: 'white',
                      padding: '4px',
                      borderRadius: '4px',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                    }}>
                      <canvas ref={monthlySalesChartRef}></canvas>
                    </div>
                  </div>
                ) : (
                  // On-market charts
                  <div style={{ 
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#f8f9fa',
                    gap: '10px',
                    marginTop: '10px'
                  }}>
                    <div style={{ 
                      width: '49.5%', 
                      height: '300px',
                      backgroundColor: 'white',
                      padding: '8px',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                    }}>
                      <canvas ref={chartRef1} style={{ width: '100%', height: '100%' }}></canvas>
                    </div>
                    <div style={{ 
                      width: '49.5%', 
                      height: '300px',
                      backgroundColor: 'white',
                      padding: '8px',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                    }}>
                      <canvas ref={chartRef2} style={{ width: '100%', height: '100%' }}></canvas>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <DataTable
                title="Cluster Summary"
                columns={clusterColumns}
                data={clusterData}
                pagination
                expandableRows
                expandableRowsComponent={({ data }) => <RegularClusterCharts data={data} />}
                expandableRowExpanded={row => !!expandedRows[row.Cluster]}
                onRowExpandToggled={(expanded, row) => handleRowExpand(row)}
                noDataComponent="No listings for this filter"
                customStyles={{
                  table: {
                    style: {
                      fontSize: '0.9rem',
                    },
                  },
                  rows: {
                    style: {
                      fontSize: '0.9rem',
                      borderBottom: '1px solid #e0e0e0',
                      minHeight: '60px',
                    },
                  },
                  headCells: {
                    style: {
                      fontSize: '0.9rem',
                      borderBottom: '2px solid #e0e0e0',
                      fontWeight: 'bold',
                      padding: '8px 8px',
                      whiteSpace: 'pre-line',
                      wordWrap: 'break-word',
                      height: 'auto',
                      minHeight: '60px',
                    },
                  },
                  cells: {
                    style: {
                      fontSize: '0.9rem',
                      padding: '8px 8px',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                    },
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>

      {/* Listings Data Table */}
      <div className="listings-container" style={{ marginTop: '20px' }}>
        <DataTable
          title="Listings Data"
          columns={columns}
          data={transformedListings}
          pagination
          noDataComponent="No listings for this filter"
          responsive
          customStyles={{
            table: {
              style: {
                fontSize: '0.95rem',
              },
            },
            rows: {
              style: {
                fontSize: '0.95rem',
                borderBottom: '1px solid #e0e0e0',
                minHeight: '50px',
                margin: 0,
              },
            },
            headCells: {
              style: {
                fontSize: '1rem',
                fontWeight: 'normal',
                borderBottom: '2px solid #e0e0e0',
                padding: '4px',
                margin: 0,
              },
            },
            cells: {
              style: {
                padding: '4px',
                margin: 0,
              },
            },
            pagination: {
              style: {
                fontSize: '0.95rem',
              },
            },
          }}
          sortField={sortField}
          sortDirection={sortDirection}
          onSort={handleSort}
          sortServer={false}
          defaultSortField="Select"
          defaultSortAsc={false}
        />
      </div>

      {/* Add a divider */}
      <div style={{ margin: '20px 0' }}></div>

      {/* Update the custom cluster section */}
      {customClusterData && customClusterData.cluster_summary && (
        <div className="custom-cluster-section" style={{ width: '100%' }}>
          {/* Remove these lines:
          {showOffMarketCharts ? (
            <OffMarketCharts data={customClusterData} />
          ) : (
            <OnMarketCharts data={customClusterData} />
          )}
          */}
        </div>
      )}
    </div>
  );
};

export default ClusteredListings;