import axios from 'axios';

// Get the API URLs from environment variables
const API_URL = process.env.REACT_APP_API_URL;
const ADVISOR_API_URL = process.env.REACT_APP_ADVISOR_API_URL;

// Create an Axios instance with the base URL
const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,  // Include credentials (cookies) in requests if needed
});

// Create a separate instance for the advisor API
const advisor_api = axios.create({
  baseURL: ADVISOR_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

// // Log each request
// api.interceptors.request.use(request => {
//   console.log('Starting Request', request);
//   return request;
// });

// // Log each response
// api.interceptors.response.use(response => {
//   console.log('Response:', response);
//   return response;
// });

export { api as default, advisor_api };
